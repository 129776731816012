<template>
  <div class="page-content">
    <div class="header">
      <div class="project-types-menu">
        <div class="projects-type-loader" v-if="!currentType">
          <LoadingComponent />
        </div>
        <template v-else>
          <div
            v-for="type in visibilityProjectsTypes"
            :key="type.id"
            @click="siwtchType(type)"
            class="project-types-menu-item"
            :class="{ active: currentType && currentType.id === type.id }"
          >
            <div class="text">
              {{ type.name }}
            </div>
            <div class="counter">
              <span
                v-if="
                  !getProjectsProcessing &&
                    currentType.id === type.id &&
                    getProjectstotalRowsType
                "
              >
                {{
                  getProjectstotalRowsType ? getProjectstotalRowsType : '--'
                }}</span
              >
              <span v-else>{{ '--' }}</span>
            </div>
          </div>
        </template>
        <!-- ACTIONS -->
        <div class="project-types-menu-button">
          <b-dropdown
            id="dropdown-left"
            text="Actions"
            variant="light"
            class="project-types-menu-button-element"
          >
            <!-- :disabled="currentType && currentType.id == 112 && readonly" -->
            <b-dropdown-item @click="createNewProject"
              >Ajouter un projet</b-dropdown-item
            >
            <!-- v-if="
                visibilityExportExcel && currentType && currentType.id != 112
              " -->
            <b-dropdown-item
              @click="exportExcel"
              v-if="visibilityExportExcel && currentType"
              >Export Excel</b-dropdown-item
            >
            <!-- v-if="
                currentType && currentType.id == 12 && visibilityImportExcel
              " -->
            <b-dropdown-item @click="importExcel" v-if="visibilityImportExcel"
              >Import Excel
              <input type="file" ref="file" :style="{ display: 'none' }" />
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <input
          type="file"
          style="display: none"
          ref="fileInput"
          accept=".xlsx"
          @input="handleFileUpload"
        />
        <!-- Loading  -->
        <div
          v-if="
            (getProjectsProcessing ||
              initLoading ||
              getProjectCountProcessing ||
              getFiltersProjectTHLoading) &&
              !getProjectsError
          "
          class="three-dots-loading mt-1 ml-2"
        >
          Chargement en cours
        </div>
        <div
          v-if="(!getProjectsProcessing || initLoading) && messageUplaodExcel"
          class="message-upload-success mt-1 ml-3"
        >
          Projets importés avec succès .
        </div>
      </div>
      <!------------------------------FILTERS TYPES PROJETS TH164 ....---------------------------------------->
      <!-- <div
        class="content-filtre-th"
        v-if="currentType && currentType.id == 112"
      > -->
      <!-- LIste des vues  -->
      <!-- <div class="select-vue">
          <div class="label-select-vue">
            Liste des vues :
          </div>
          <div class="select-option-vue">
            <Multiselect
              v-model="selectedVues"
              label="label"
              track-by="id"
              :showLabels="false"
              :closeOnSelect="true"
              open-direction="bottom"
              :allow-empty="false"
              :options="vueTable || []"
              :searchable="true"
              :internal-search="true"
              placeholder="Rechercher vue"
              @input="selectedFilterTh(selectedVues)"
            >
              <span slot="noResult">Aucun vue trouvé.</span>
              <span slot="noOptions">Aucun vue trouvé.</span>
            </Multiselect>
          </div>
        </div> -->
      <!-- Ajouter et selectionner Filters -->
      <!-- <div class="actions-filter-th" v-if="!readonly">
          <b-button
            variant="light"
            class="project-types-menu-button-element"
            @click.prevent="showModalSelectedFilterTH"
            ><font-awesome-icon class="filter-icon" :icon="['fas', 'filter']"
          /></b-button>
        </div> -->
      <!-- Gestion des filters th 164 -->
      <b-modal
        no-close-on-backdrop
        id="selected_Filter_th"
        ref="selected_Filter_th"
        title="Gestion des filtres"
        :hide-footer="true"
        size="lg"
      >
        <form @submit.prevent="selectionnerFilterTH(selectedNameFilterTH)">
          <b-form-group label="Liste des filtres :">
            <div class="content-actions-filter-th">
              <Multiselect
                class="select-filter-th"
                v-model="selectedNameFilterTH"
                :options="[{ id: null, name: '' }, ...getFiltersProjectTH]"
                placeholder="Rechercher un filtre"
                :close-on-select="true"
                :clear-on-select="true"
                :preserve-search="true"
                :showLabels="false"
                label="name"
                track-by="id"
                :allow-empty="false"
                @input="selectedFilterTHCreer(selectedNameFilterTH)"
                :loading="
                  getListFiltersProjectTHLoading || getFiltersProjectTHLoading
                "
                :disabled="
                  getListFiltersProjectTHLoading ||
                    !disabled ||
                    getFiltersProjectTHLoading
                "
              >
                <span slot="noResult">Aucune filtre trouvée.</span>
                <span slot="noOptions">Aucune filtre trouvée.</span>
              </Multiselect>
              <div
                class="flex-content"
                :class="{ 'disabled-buttons': !visibilityFilterTh }"
              >
                <div class="btn-filter-th">
                  <b-button
                    href=""
                    @click.prevent="showModalAddFilterTH"
                    class="btn-th"
                    variant="success"
                    title="Ajouter un filtre"
                  >
                    <font-awesome-icon class icon="plus" />
                  </b-button>
                </div>
                <div class="btn-filter-th">
                  <b-button
                    href=""
                    @click.prevent="showModalUpdatedFilterTH"
                    class="btn-th"
                    variant="primary"
                    title="Modifier un filtre"
                    :disabled="
                      !selectedNameFilterTH || !selectedNameFilterTH.id
                    "
                  >
                    <font-awesome-icon class icon="pencil-alt" />
                  </b-button>
                </div>
                <div class="btn-filter-th">
                  <b-button
                    href=""
                    @click.prevent="
                      showModalDeletedFilterTH(selectedNameFilterTH)
                    "
                    class="btn-th"
                    variant="danger"
                    title="Supprimer un filtre"
                    :disabled="
                      !selectedNameFilterTH || !selectedNameFilterTH.id
                    "
                  >
                    <font-awesome-icon class icon="trash-alt" />
                  </b-button>
                </div>
              </div>
            </div>
          </b-form-group>
          <div
            v-if="
              selectedNameFilterTH && selectedNameFilterTH.id && conditionsTH
            "
          >
            <FilterProjetTH
              :fields="getFieldsTHToFiltre"
              v-model="conditionsTH"
              :typeProject="currentType"
              :disabled="disabled"
            ></FilterProjetTH>
            <b-form-group
              label="Nom du filtre"
              v-if="filterToUpdate && !disabled"
            >
              <b-form-input
                v-model="filterToUpdate.nameFilterTH"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="message">
            <div v-if="getFiltersProjectTHLoading" class="three-dots-loading">
              Chargement en cours
            </div>
            <div v-if="errorFilterTH" class="error">
              <ul v-if="Array.isArray(errorFilterTH)">
                <li v-for="(e, index) in errorFilterTH" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ errorFilterTH }}</span>
            </div>
            <div v-if="getFiltersProjectTHError" class="error">
              <ul v-if="Array.isArray(getFiltersProjectTHError)">
                <li v-for="(e, index) in getFiltersProjectTHError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getFiltersProjectTHError }}</span>
            </div>
          </div>
          <div class="form-actions" v-if="!disabled">
            <b-button
              @click="retourModalSelectedFilterTH(selectedNameFilterTH)"
              variant="outline-secondary"
            >
              Retour
            </b-button>
            <b-button variant="success" @click.prevent="modifierFilterTH">
              Enregistrer
            </b-button>
          </div>
          <div class="form-actions" v-else>
            <b-button
              @click="hideModal('selected_Filter_th')"
              variant="outline-secondary"
            >
              Annuler
            </b-button>
            <b-button
              variant="success"
              type="submit"
              v-if="selectedNameFilterTH && selectedNameFilterTH.id"
            >
              Enregistrer
            </b-button>
          </div>
        </form>
      </b-modal>

      <!-- Ajouter Filter th164 -->
      <b-modal
        no-close-on-backdrop
        id="add_Filter_th"
        ref="add_Filter_th"
        title="Ajouter un filtre"
        :hide-footer="true"
        @show="resetModalAddFilterTH"
        @hidden="resetModalAddFilterTH()"
        size="lg"
      >
        <form @submit.prevent="addFilterTH">
          <FilterProjetTH
            :fields="getFieldsTHToFiltre"
            v-model="conditionsFiltreProjetTH"
            :typeProject="currentType"
            :disabled="false"
          ></FilterProjetTH>
          <b-form-group label="Nom du filtre">
            <b-form-input v-model="nameFilterTH"></b-form-input>
          </b-form-group>
          <div class="message">
            <div v-if="getFiltersProjectTHLoading" class="three-dots-loading">
              Chargement en cours
            </div>
            <div v-if="errorFilterTH" class="error">
              <ul v-if="Array.isArray(errorFilterTH)">
                <li v-for="(e, index) in errorFilterTH" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ errorFilterTH }}</span>
            </div>
            <div v-if="getFiltersProjectTHError" class="error">
              <ul v-if="Array.isArray(getFiltersProjectTHError)">
                <li v-for="(e, index) in getFiltersProjectTHError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getFiltersProjectTHError }}</span>
            </div>
          </div>
          <div class="form-actions">
            <b-button
              @click="hideModal('add_Filter_th')"
              variant="outline-secondary"
            >
              Annuler
            </b-button>
            <b-button variant="success" type="submit"> Enregistrer </b-button>
          </div>
        </form>
      </b-modal>
      <!-- deleted Filer th 164 -->
      <b-modal
        no-close-on-backdrop
        ref="deleted_Filter_th"
        id="deleted_Filter_th"
        title=" Supprimer un filtre"
        :hide-footer="true"
      >
        <div>
          <p class="parg">
            Êtes-vous certain de vouloir supprimer ce filtre
            <strong>
              {{ filterTHToDelete ? filterTHToDelete.name : '' }}</strong
            >
            ?
          </p>
        </div>
        <div class="form-actions">
          <b-button
            @click="hideModal('deleted_Filter_th')"
            variant="outline-secondary"
            >Annuler</b-button
          >
          <b-button variant="success" @click="supprimerFilterTH"
            >Valider</b-button
          >
        </div>
        <div class="message">
          <div v-if="getFiltersProjectTHLoading" class="three-dots-loading">
            Chargement en cours
          </div>
          <div v-if="getFiltersProjectTHError" class="error">
            <ul v-if="Array.isArray(getFiltersProjectTHError)">
              <li v-for="(e, index) in getFiltersProjectTHError" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getFiltersProjectTHError }}</span>
          </div>
        </div>
      </b-modal>
      <!---------------------- FILTERS TYPES PROJETS RENO GLOBAL ....------------------------------->
      <!-- v-if="
          (!currentType || proccessingPipelineFilter) &&
            currentType &&
            currentType.id != 112
        " -->
      <div
        class="filter-loader"
        v-if="!currentType || proccessingPipelineFilter"
      >
        <LoadingComponent />
      </div>
      <div v-else class="display-flex">
        <!-- v-if="currentType && currentType.id != 112" -->
        <div
          class="filters"
          :class="{ 'pag-scroll': filterPrincipal }"
          v-if="currentType"
        >
          <template>
            <div
              class="default-filters"
              :class="{
                'custom-on':
                  (formatedCustomFilters && formatedCustomFilters.length) ||
                  filterPrincipal
              }"
            >
              <div
                class="filter-item-container"
                v-for="(filter, index) in pipelineFilters"
                :key="filter.id"
              >
                <div
                  class="filter-item"
                  @click="siwtchFilterPipeline(filter, index)"
                  :class="{
                    active:
                      currentFilterPipeline &&
                      currentFilterPipeline.id === filter.id &&
                      !formatedCustomFilters.length &&
                      !filterPrincipal
                  }"
                >
                  <div class="text">
                    {{ filter.name }}
                  </div>
                  <div class="counter">
                    <span
                      v-if="
                        !getProjectsProcessing &&
                          currentFilterPipeline &&
                          currentFilterPipeline.id === filter.id &&
                          getProjectstotalRowsStade &&
                          !formatedCustomFilters.length &&
                          !filterPrincipal
                      "
                    >
                      {{
                        getProjectstotalRowsStade
                          ? getProjectstotalRowsStade
                          : '--'
                      }}</span
                    >
                    <span v-else>{{ '--' }}</span>
                  </div>
                </div>
              </div>
              <div
                class="filter-item-container"
                v-if="
                  (!pipelineFilters || !pipelineFilters.length) &&
                    (!formatedCustomFilters || !formatedCustomFilters.length) &&
                    !selectedFilterId
                "
              >
                <span class="aucun-pip"> Aucun pipeline principal</span>
              </div>
            </div>
            <div class="custom-filters">
              <div
                class="filter-item-container"
                v-for="(item, index) in formatedCustomFilters"
                :key="index"
              >
                <div class="filter-item">
                  <span class="label" v-if="item.valueLabel"
                    >{{ item.keyLabel }}
                  </span>
                  <span class="label" v-else>Filtre personnalisé</span>
                  <span class="ml-1" v-if="item.valueLabel">
                    : {{ item.valueLabel }}</span
                  >
                  <span class="ml-1" v-else> : {{ item.keyLabel }}</span>
                  <span class="action" @click="deleteCustomFilter(index, item)">
                    <font-awesome-icon icon="times" />
                  </span>
                </div>
              </div>
              <div
                class="filter-item-container"
                v-if="formatedCustomFilters.length && !selectedFilterId"
              >
                <div class="filter-item">
                  <span class="label">Filtre personnalisé</span>
                  <span class="action" @click="resetCustomFilter">
                    <font-awesome-icon icon="times" />
                  </span>
                </div>
              </div>
            </div>
            <!-- Affichage de contenu de filter projet TH  -->
            <div
              class="custom-filters-th"
              :class="{
                'custom-on': formatedCustomFilters.length
              }"
              v-if="
                formatedCustomFiltersTH &&
                  (formatedCustomFiltersTH.and ||
                    formatedCustomFiltersTH.or ||
                    (formatedCustomFiltersTH.and && formatedCustomFiltersTH.or))
              "
            >
              <div class="filter-item-container-th">
                <div class="content-filter">
                  <div
                    class="filter-item-th"
                    v-for="(item, index) in formatedCustomFiltersTH.and"
                    :key="'and' + index"
                  >
                    <span class="label">
                      {{ item.field_key.name }}
                    </span>
                    <span class="label-content">
                      {{
                        item.value && typeof item.value != 'object'
                          ? ' : ' +
                            item.operator.label +
                            '  ' +
                            item.value +
                            ' '
                          : item.value && typeof item.value == 'object'
                          ? ' : ' +
                            item.operator.label +
                            '  ' +
                            item.value.name +
                            ' '
                          : ': ' + item.operator.label + '  '
                      }}
                    </span>
                    <span
                      v-if="index != formatedCustomFiltersTH.and.length - 1"
                    >
                      ,</span
                    >
                  </div>

                  <span
                    v-if="
                      formatedCustomFiltersTH.and &&
                        formatedCustomFiltersTH.and.length &&
                        formatedCustomFiltersTH.or &&
                        formatedCustomFiltersTH.or.length
                    "
                    >, {{ ' (' }}
                  </span>
                  <div
                    class="filter-item-th"
                    v-for="(item, index) in formatedCustomFiltersTH.or"
                    :key="'or' + index"
                  >
                    <span class="label">
                      {{ item.field_key.name }}
                    </span>
                    <span class="label-content">
                      {{
                        item.value && typeof item.value != 'object'
                          ? ' : ' +
                            item.operator.label +
                            '  ' +
                            item.value +
                            ' '
                          : item.value && typeof item.value == 'object'
                          ? ' : ' +
                            item.operator.label +
                            '  ' +
                            item.value.name +
                            ' '
                          : ': ' + item.operator.label + '  '
                      }}
                      <span
                        v-if="index != formatedCustomFiltersTH.or.length - 1"
                      >
                        {{ '  ou ' }}
                      </span>
                    </span>
                  </div>
                  <span
                    v-if="
                      formatedCustomFiltersTH.and &&
                        formatedCustomFiltersTH.and.length &&
                        formatedCustomFiltersTH.or &&
                        formatedCustomFiltersTH.or.length
                    "
                  >
                    )
                  </span>
                  <div
                    class="action"
                    title="Supprimer filtres"
                    @click="deleteCustomFilterTH(formatedCustomFiltersTH)"
                  >
                    <font-awesome-icon icon="times" />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <!-- Ajouter et selectionner Filters -->
        <div class="icon-custom-filter">
          <div
            v-if="
              formatedCustomFilters &&
                formatedCustomFilters.length &&
                notSave &&
                !readonly
            "
            class="icon-save-filter"
            @click="ModalSaveFilters"
            title="Enregistrer un filtre"
          >
            <font-awesome-icon class="icon ml-1" icon="save" />
          </div>
          <!-- <div
                class="icon-select-filter"
                :class="{ top: pipelineFilters && pipelineFilters.length }"
                @click="ModalSelectFilters"
                title="Sélectionner un filtre"
              >
                <font-awesome-icon
                  class="filter-icon"
                  :icon="['fas', 'filter']"
                />
              </div> -->
        </div>
        <div class="actions-filter-th" v-if="!readonly">
          <b-button
            variant="light"
            class="project-types-menu-button-element"
            @click.prevent="showModalSelectedFilterTH"
            ><font-awesome-icon class="filter-icon" :icon="['fas', 'filter']"
          /></b-button>
        </div>
      </div>
    </div>
    <!-- v-if="
        getProjectCountProcessing &&
          !getProjectsError &&
          currentType &&
          currentType.id != 112
      " -->
    <div
      class="pagination-loader"
      v-if="getProjectCountProcessing && !getProjectsError && currentType"
    >
      <LoadingComponent />
    </div>
    <template v-else>
      <!-- v-if="currentType && currentType.id != 112" -->
      <div class="pagination-counter">
        <b-pagination
          class="pagination"
          size="sm"
          v-model="currentPage"
          :total-rows="getProjectstotalRows"
          @change="handlePaginationChange"
          :per-page="50"
          first-number
          last-number
        ></b-pagination>
        <div
          class="counter-filter"
          v-if="formatedCustomFilters.length || filterPrincipal"
        >
          {{ getProjectstotalRows }} projets
        </div>
      </div>
    </template>
    <!-- Pagination de table TH 146 -->
    <!-- <div
      class="pagination-th"
      v-if="
        currentType &&
          currentType.id == 112 &&
          selectedVues &&
          selectedVues.id == 0
      "
    >
      <div class="per-page-th">
        <div class="label-per-page-th">
          Tout afficher :
        </div>
        <b-form-checkbox
          v-model="TousLingeTH"
          class="checkbox-line-th"
          :value="1"
          :unchecked-value="0"
          @change="handleTousLigneTableTH(TousLingeTH)"
        ></b-form-checkbox>
        <div class="border-th"></div>
      </div>
      <div class="per-page-th">
        <div class="label-per-page-th">
          Nombre de ligne :
        </div>
        <div class="select-option-per-page-th">
          <b-form-select
            class="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            @change="handlePerPageChange"
            size="sm"
          ></b-form-select>
        </div>
      </div>
      <div
        class="pagination-counter"
        v-if="
          !TousLingeTH &&
            perPage != '' &&
            (!getProjectsProcessing || !initLoading) &&
            !getProjectsError
        "
      >
        <b-pagination
          class="pagination"
          size="sm"
          v-model="currentPage"
          :total-rows="getProjectstotalRowsTH"
          @change="handlePaginationChangeTH"
          :per-page="perPage"
          first-number
          last-number
        ></b-pagination>
      </div>
    </div> -->
    <!-------------------------------- TABLE PROJET-------------------------------------------->
    <!-- v-if="currentType && currentType.id != 112" -->
    <div class="content">
      <div class="projects-table">
        <table
          :style="{
            width: typeCPF
              ? '2050px'
              : typeISO
              ? !hiddenColonneTypeIsolation && !hiddenColonneTypeTravaux
                ? '2940px'
                : '190px'
              : '190px'
          }"
        >
          <colgroup>
            <!-- checkbox -->
            <col width="30px" />
            <!-- Créé le -->
            <col width="100px" />
            <!-- Derniere modification -->
            <col width="150px" />
            <!-- Contact -->
            <col width="180px" />
            <!-- Valo -->
            <!-- <col width="120px" v-if="typeISO" /> -->
            <!-- Source -->
            <col width="160px" v-if="!typePhotovoltaique" />
            <!-- Stade -->
            <col width="150px" />
            <!-- Sédentaire -->
            <!-- <col width="120px" /> -->
            <!-- Appels -->
            <!-- <col width="80px" /> -->
            <!-- Prochaine activité -->
            <col width="110px" v-if="typeCPF" />
            <!-- Closing le -->
            <!-- <col width="120px" /> -->
            <!-- ISO -->
            <template v-if="typeISO">
              <!-- Commercial -->
              <!-- <col width="120px" /> -->
              <!-- Pré-visiteur -->
              <!-- <col width="120px" /> -->
              <!-- Rendez-vous le -->
              <!-- <col width="140px" /> -->
              <!-- Poseur -->
              <col width="180px" v-if="!typePhotovoltaique" />
              <!-- visiteur technique -->
              <col width="160px" />
              <!-- Business Developper -->
              <!-- <col width="200px" /> -->
              <!-- chauffage -->
              <col width="120px" />
              <!--  ISOLATION-->
              <template v-if="!hiddenColonneTypeIsolation">
                <!-- <col width="120px" />
                <col width="90px" />
                <col width="90px" /> -->
                <col width="120px" />
                <col width="90px" />
                <col width="90px" />
              </template>
              <col v-else width="100px" />
              <!--  Docs-->
              <col width="80px" />
              <!--  octroi-->
              <col width="80px" />
              <template>
                <!--  Cumac -->
                <col width="100px" />
                <!-- Travaux -->
                <!-- <col width="100px" /> -->
                <!-- Type -->
                <col width="150px" />
              </template>
              <!-- <col v-else width="100px" /> -->
              <!-- Type de travaux -->
              <template v-if="!hiddenColonneTypeTravaux">
                <col width="90px"/>
                <col width="60px"/>
                <col width="70px"/>
                <col width="90px"/>
                <col width="90px"/>
                <col width="90px"/>
                <col width="90px"/>
                <col width="60px"
              /></template>
              <col v-else width="100px" />
              <!-- <col width="60px" /> -->
              <!-- Dossier -->
              <col width="30px" />
              <col width="30px" />
              <col width="30px" />
              <!-- <col width="30px" /> -->
            </template>
            <!-- CPF -->
            <template v-if="typeCPF">
              <!-- Budget -->
              <col width="100px" />
              <!-- Progression -->
              <col width="120px" />
              <!-- Formation -->
              <col width="180px" />
              <!-- Date de debut -->
              <col width="140px" />
              <!-- Date de fin -->
              <col width="140px" />
            </template>
          </colgroup>

          <tr
            :style="{
              height: typeCPF ? '50px' : '25px'
            }"
          >
            <!-- checkbox -->
            <th
              :rowspan="typeCPF ? 1 : 2"
              class="sticky sticky-up index-3 tagged-cell r"
            >
              <a-checkbox
                :partialSelect="parcialSelect"
                v-model="selectAll"
                @change="handleSelectedProject($event, true)"
              />
            </th>
            <!-- creation_date -->
            <th
              :rowspan="typeCPF ? 1 : 2"
              class="sticky sticky-up index-3 tagged-cell offset r"
              @click="sort('creation_date')"
            >
              <IconSort
                :sortDirection="sortDirection"
                :column="currentSort == 'creation_date' ? true : false"
              >
                Créé le
              </IconSort>
              <TooltipDateFilter
                @change="handlChangeCustomFilter"
                placement="auto"
                :keyName="'creation_date'"
                :keyLabel="'Créé le'"
                :styleFilterHiddenCol="
                  hiddenColonneTypeIsolation && hiddenColonneTypeTravaux
                    ? true
                    : hiddenColonneTypeIsolation && !hiddenColonneTypeTravaux
                    ? false
                    : !hiddenColonneTypeIsolation && hiddenColonneTypeTravaux
                    ? true
                    : false
                "
              >
              </TooltipDateFilter>
              <!-- <div class="extra-border"></div> -->
            </th>
            <!-- Derniere modification -->
            <th
              :rowspan="typeCPF ? 1 : 2"
              class="sticky sticky-up index-3 tagged-cell offset-2 r"
              @click="sort('modification_date')"
            >
              <IconSort
                :sortDirection="sortDirection"
                :column="currentSort == 'modification_date' ? true : false"
              >
                Dernière <br />
                modification
              </IconSort>
              <TooltipDateFilter
                @change="handlChangeCustomFilter"
                placement="auto"
                :keyName="'modification_date'"
                :keyLabel="'Dernière modification'"
                :styleFilterHiddenCol="
                  hiddenColonneTypeIsolation && hiddenColonneTypeTravaux
                    ? true
                    : hiddenColonneTypeIsolation && !hiddenColonneTypeTravaux
                    ? false
                    : !hiddenColonneTypeIsolation && hiddenColonneTypeTravaux
                    ? true
                    : false
                "
              >
              </TooltipDateFilter>
              <div class="extra-border"></div>
            </th>
            <!-- Contact  -->
            <th
              :rowspan="typeCPF ? 1 : 2"
              class="sticky-up"
              @click="sort('contact_full_name')"
            >
              <IconSort
                :sortDirection="sortDirection"
                :column="currentSort == 'contact_full_name' ? true : false"
              >
                Contact
              </IconSort>
              <TooltipContactFilter
                placement="auto"
                @change="handlChangeCustomFilter"
                :styleFilterHiddenCol="
                  hiddenColonneTypeIsolation && hiddenColonneTypeTravaux
                    ? true
                    : hiddenColonneTypeIsolation && !hiddenColonneTypeTravaux
                    ? false
                    : !hiddenColonneTypeIsolation && hiddenColonneTypeTravaux
                    ? true
                    : false
                "
              >
              </TooltipContactFilter>
            </th>
            <!-- Valo  -->
            <!-- <th
              @click="sort('zone')"
              :rowspan="typeCPF ? 1 : 2"
              class="sticky-up"
              v-if="typeISO"
            >
              <IconSort
                :sortDirection="sortDirection"
                :column="currentSort == 'zone' ? true : false"
              >
                Valo
              </IconSort>
              <TooltipValorisationFilter
                placement="auto"
                @change="handlChangeCustomFilter"
              >
              </TooltipValorisationFilter>
            </th> -->
            <!-- Source  -->
            <th
              :rowspan="typeCPF ? 1 : 2"
              class="sticky-up"
              @click="sort('source')"
              v-if="!typePhotovoltaique"
            >
              <IconSort
                :sortDirection="sortDirection"
                :column="currentSort == 'source' ? true : false"
              >
                Source
              </IconSort>
              <TooltipSource
                placement="auto"
                @change="handlChangeCustomFilter"
                :styleFilterHiddenCol="
                  hiddenColonneTypeIsolation && hiddenColonneTypeTravaux
                    ? true
                    : hiddenColonneTypeIsolation && !hiddenColonneTypeTravaux
                    ? false
                    : !hiddenColonneTypeIsolation && hiddenColonneTypeTravaux
                    ? true
                    : false
                "
              >
              </TooltipSource>
            </th>
            <!-- Stade -->
            <th
              :rowspan="typeCPF ? 1 : 2"
              class="sticky-up"
              @click="sort('stage')"
            >
              <IconSort
                :sortDirection="sortDirection"
                :column="currentSort == 'stage' ? true : false"
              >
                Stade
              </IconSort>
              <TooltipStadeFilter
                placement="auto"
                @change="handlChangeCustomFilter"
                :stageOptions="currentStages"
                :styleFilterHiddenCol="
                  hiddenColonneTypeIsolation && hiddenColonneTypeTravaux
                    ? true
                    : hiddenColonneTypeIsolation && !hiddenColonneTypeTravaux
                    ? false
                    : !hiddenColonneTypeIsolation && hiddenColonneTypeTravaux
                    ? true
                    : false
                "
              >
              </TooltipStadeFilter>
            </th>
            <!-- Sédentaire  -->
            <!-- <th
              :rowspan="typeCPF ? 1 : 2"
              class="sticky-up"
              @click="sort('comm_sed')"
            >
              <IconSort
                :sortDirection="sortDirection"
                :column="currentSort == 'comm_sed' ? true : false"
              >
                Sédentaire
              </IconSort>
              <TooltipCommercialSed
                placement="auto"
                @change="handlChangeCustomFilter"
              >
              </TooltipCommercialSed>
            </th> -->
            <!-- Appel  -->
            <!-- <th :rowspan="typeCPF ? 1 : 2" class="sticky-up"> -->
            <!-- <IconSort
                :sortDirection="sortDirection"
                :column="currentSort == 'appel_count' ? true : false"
              >
                @click="sort('appel_count')" -->
            <!-- Appels -->
            <!-- </IconSort> -->
            <!-- </th> -->
            <!-- Prochaine activité -->
            <th v-if="typeCPF" :rowspan="typeCPF ? 1 : 2" class="sticky-up">
              <!-- @click="sort('activity_recent')"
              <IconSort
                :sortDirection="sortDirection"
                :column="currentSort == 'activity_recent' ? true : false"
              >
              </IconSort> -->
              Prochaine activité
            </th>
            <!-- Closing le -->
            <!-- <th
              :rowspan="typeCPF ? 1 : 2"
              class="sticky-up"
              @click="sort('closing_date')"
            >
              <IconSort
                class="mrg"
                :sortDirection="sortDirection"
                :column="currentSort == 'closing_date' ? true : false"
              >
                Closing le
              </IconSort>
              <TooltipDateFilter
                placement="auto"
                @change="handlChangeCustomFilter"
                :keyName="'closing_date'"
                :keyLabel="'Closé le'"
              >
              </TooltipDateFilter>
            </th> -->
            <!-- COLONNE TYPE ISO -->
            <template v-if="typeISO">
              <!-- Commercial  -->
              <!-- <th rowspan="2" class="sticky-up" @click="sort('comm_terrain')">
                <IconSort
                  :sortDirection="sortDirection"
                  :column="currentSort == 'comm_terrain' ? true : false"
                >
                  Commercial</IconSort
                >
                <TooltipCommercialTerrain
                  placement="auto"
                  @change="handlChangeCustomFilter"
                >
                </TooltipCommercialTerrain>
              </th> -->
              <!-- Pré visiteur  -->
              <!-- <th rowspan="2" class="sticky-up" @click="sort('comm_ite')">
                <IconSort
                  :sortDirection="sortDirection"
                  :column="currentSort == 'comm_ite' ? true : false"
                >
                  Pré-visiteur
                </IconSort>
                <TooltipEcoTechnicien
                  placement="auto"
                  @change="handlChangeCustomFilter"
                >
                </TooltipEcoTechnicien>
              </th> -->
              <!-- Rendez-vous le -->
              <!-- <th rowspan="2" class="sticky-up" @click="sort('previsite_date')">
                <IconSort
                  class="mrg"
                  :sortDirection="sortDirection"
                  :column="currentSort == 'previsite_date' ? true : false"
                >
                  Rendez-vous le
                </IconSort>
                <TooltipPreVisiteFilter
                  placement="auto"
                  @change="handlChangeCustomFilter"
                  :keyName="'previsite_date'"
                  :keyLabel="'Rendez-vous le'"
                >
                </TooltipPreVisiteFilter>
              </th> -->

              <!-- Poseur  -->
              <th
                rowspan="2"
                class="sticky-up"
                @click="sort('poseur')"
                v-if="!typePhotovoltaique"
              >
                <IconSort
                  :sortDirection="sortDirection"
                  :column="currentSort == 'poseur' ? true : false"
                >
                  Assistants maître <br />
                  d'ouvrage
                </IconSort>
                <TooltipPoseurFilter
                  placement="auto"
                  @change="handlChangeCustomFilter"
                  :styleFilterHiddenCol="
                    hiddenColonneTypeIsolation && hiddenColonneTypeTravaux
                      ? true
                      : hiddenColonneTypeIsolation && !hiddenColonneTypeTravaux
                      ? false
                      : !hiddenColonneTypeIsolation && hiddenColonneTypeTravaux
                      ? true
                      : false
                  "
                >
                </TooltipPoseurFilter>
              </th>
              <!-- Visiteur technique  -->
              <th rowspan="2" class="sticky-up" @click="sort('visiteur_tech')">
                <IconSort
                  :sortDirection="sortDirection"
                  :column="currentSort == 'visiteur_tech' ? true : false"
                >
                  Visiteur technique
                </IconSort>
                <TooltipVisiteurTechnique
                  placement="auto"
                  @change="handlChangeCustomFilter"
                  :styleFilterHiddenCol="
                    hiddenColonneTypeIsolation && hiddenColonneTypeTravaux
                      ? true
                      : hiddenColonneTypeIsolation && !hiddenColonneTypeTravaux
                      ? false
                      : !hiddenColonneTypeIsolation && hiddenColonneTypeTravaux
                      ? true
                      : false
                  "
                >
                </TooltipVisiteurTechnique>
              </th>
              <!-- Business Developper -->
              <!-- <th rowspan="2" class="sticky-up">Business Developper</th> -->
              <!-- chauffage -->
              <th
                rowspan="2"
                class="sticky-up"
                @click="sort('team_chauffage_id')"
              >
                <IconSort
                  :sortDirection="sortDirection"
                  :column="currentSort == 'team_chauffage_id' ? true : false"
                >
                  Filiale Chauffage
                </IconSort>
              </th>
              <!-- ISOLATION-->
              <th
                :colspan="hiddenColonneTypeIsolation ? 1 : 3"
                :rowspan="hiddenColonneTypeIsolation ? 2 : 1"
                class="sticky-up"
              >
                <span class="icon-minus">
                  <font-awesome-icon
                    class="icon"
                    icon="minus-square"
                    @click="
                      clickHiddenColonneTypeIsolation(
                        hiddenColonneTypeIsolation
                      )
                    "
                /></span>
                <span class="title-icon-minus"
                  >Filiales <br v-if="hiddenColonneTypeIsolation" />
                  Isolations</span
                >
              </th>
              <!--  Docs -->
              <th rowspan="2" class="sticky-up" @click="sort('file_cat_color')">
                <IconSort
                  :sortDirection="sortDirection"
                  :column="currentSort == 'file_cat_color' ? true : false"
                >
                  Docs
                </IconSort>
                <TooltipDocsFilter
                  placement="auto"
                  @change="handlChangeCustomFilter"
                  :keyField="'file_cat_color'"
                  :styleFilterHiddenCol="
                    hiddenColonneTypeIsolation && hiddenColonneTypeTravaux
                      ? true
                      : hiddenColonneTypeIsolation && !hiddenColonneTypeTravaux
                      ? false
                      : !hiddenColonneTypeIsolation && hiddenColonneTypeTravaux
                      ? true
                      : false
                  "
                >
                </TooltipDocsFilter>
              </th>
              <!--  Octroi -->
              <th
                rowspan="2"
                class="sticky-up"
                @click="sort('lettre_acroi_color')"
              >
                <IconSort
                  :sortDirection="sortDirection"
                  :column="currentSort == 'lettre_acroi_color' ? true : false"
                >
                  <span class="mr-2"> Octroi</span>
                </IconSort>
                <TooltipDocsFilter
                  placement="auto"
                  @change="handlChangeCustomFilter"
                  :keyField="'lettre_acroi_color'"
                  :styleFilterHiddenCol="
                    hiddenColonneTypeIsolation && hiddenColonneTypeTravaux
                      ? true
                      : hiddenColonneTypeIsolation && !hiddenColonneTypeTravaux
                      ? false
                      : !hiddenColonneTypeIsolation && hiddenColonneTypeTravaux
                      ? true
                      : false
                  "
                >
                </TooltipDocsFilter>
              </th>
              <!-- Cumac_Typê  -->
              <!-- cumac -->
              <th rowspan="2" class="sticky-up" @click="sort('cumac')">
                <IconSort
                  :sortDirection="sortDirection"
                  :column="currentSort == 'cumac' ? true : false"
                >
                  <span class="mr-2"> Cumac </span>
                </IconSort>
                <TooltipCumacFilter
                  placement="auto"
                  @change="handlChangeCustomFilter"
                  :styleFilterHiddenCol="
                    hiddenColonneTypeIsolation && hiddenColonneTypeTravaux
                      ? true
                      : hiddenColonneTypeIsolation && !hiddenColonneTypeTravaux
                      ? false
                      : !hiddenColonneTypeIsolation && hiddenColonneTypeTravaux
                      ? true
                      : false
                  "
                >
                </TooltipCumacFilter>
              </th>
              <!-- Type  -->
              <th
                rowspan="2"
                class="sticky-up"
                @click="sort('type_renovation')"
              >
                <IconSort
                  :sortDirection="sortDirection"
                  :column="currentSort == 'type_renovation' ? true : false"
                >
                  Type
                  <TooltipType
                    placement="auto"
                    @change="handlChangeCustomFilter"
                    :keyName="'travaux_date'"
                    :keyLabel="'Date travaux'"
                    :styleFilterHiddenCol="
                      hiddenColonneTypeIsolation && hiddenColonneTypeTravaux
                        ? true
                        : hiddenColonneTypeIsolation &&
                          !hiddenColonneTypeTravaux
                        ? false
                        : !hiddenColonneTypeIsolation &&
                          hiddenColonneTypeTravaux
                        ? true
                        : false
                    "
                  >
                  </TooltipType>
                </IconSort>
              </th>
              <!-- <th
                :colspan="hiddenColonneCumacType ? 1 : 2"
                :rowspan="hiddenColonneCumacType ? 2 : 1"
                class="sticky-up"
              >
                <span class="icon-minus">
                  <font-awesome-icon
                    class="icon"
                    icon="minus-square"
                    @click="clickHiddenColonneCumacType(hiddenColonneCumacType)"
                /></span>
                <span class="title-icon-minus"
                  >Cumac<br v-if="hiddenColonneCumacType" />
                  et type</span
                >
              </th> -->
              <!-- Travaux -->
              <!-- <th rowspan="2" class="sticky-up" @click="sort('travaux_date')">
                <IconSort
                  :sortDirection="sortDirection"
                  :column="currentSort == 'travaux_date' ? true : false"
                >
                  Travaux
                </IconSort>
                <TooltipTravauxFilter
                  placement="auto"
                  @change="handlChangeCustomFilter"
                  :keyName="'travaux_date'"
                  :keyLabel="'Date travaux'"
                >
                </TooltipTravauxFilter>
              </th> -->

              <!-- Type de travaux -->
              <th
                :colspan="hiddenColonneTypeTravaux ? 1 : 8"
                :rowspan="hiddenColonneTypeTravaux ? 2 : 1"
                class="sticky-up"
              >
                <span class="icon-minus">
                  <font-awesome-icon
                    class="icon"
                    icon="minus-square"
                    @click="
                      clickHiddenColonneTypeTravaux(hiddenColonneTypeTravaux)
                    "
                /></span>
                <span class="title-icon-minus"
                  >Type<br v-if="hiddenColonneTypeTravaux" />
                  Travaux</span
                >
              </th>
              <!-- Dossier -->
              <th colspan="3" class="sticky-up">
                Dossier
                <!-- <TooltipDossierFilter
                  placement="auto"
                  @change="handlChangeCustomFilter"
                >
                </TooltipDossierFilter> -->
              </th>
            </template>
            <!-- COLONNE TYPE CPF -->
            <template v-if="typeCPF">
              <!-- Budget -->
              <th :rowspan="typeCPF ? 1 : 2" class="sticky-up">Budget</th>
              <!-- Progression -->
              <th :rowspan="typeCPF ? 1 : 2" class="sticky-up">Progression</th>
              <!-- Formation -->
              <th :rowspan="typeCPF ? 1 : 2" class="sticky-up">Formation</th>
              <!-- Date de debut -->
              <th
                :rowspan="typeCPF ? 1 : 2"
                class="sticky-up"
                @click="sort('date_debut_formation')"
              >
                <IconSort
                  :sortDirection="sortDirection"
                  :column="currentSort == 'date_debut_formation' ? true : false"
                >
                  Date de debut
                </IconSort>
                <TooltipDateFilter
                  @change="handlChangeCustomFilter"
                  placement="auto"
                  :keyName="'date_debut_formation'"
                  :keyLabel="'Date de debut'"
                ></TooltipDateFilter>
              </th>
              <!-- Date de fin -->
              <th :rowspan="typeCPF ? 1 : 2" class="sticky-up">Date de fin</th>
            </template>
          </tr>
          <tr v-if="typeISO">
            <!-- <th class="less-padding sticky-up offset" title="AUDIT">AUDIT</th>
            <th class="less-padding sticky-up offset" title="ISO 1€">ISO 1€</th>
            <th class="less-padding sticky-up offset" title="102PL">102PL</th>
            <th class="less-padding sticky-up offset" title="102PL">102PL</th>
            <th class="less-padding sticky-up offset" title="PAC">PAC</th>
            <th class="less-padding sticky-up offset" title="ECS">ECS</th>
            <th class="less-padding sticky-up offset" title="ITE">ITE</th> -->
            <!-- Isolation-->
            <template v-if="!hiddenColonneTypeIsolation">
              <!-- <th
                class="less-padding sticky-up offset"
                @click="sort('team_isolation_plancher_bas_id')"
                title="Plancher Bas"
              >
                <IconSort
                  :sortDirection="sortDirection"
                  :column="
                    currentSort == 'team_isolation_plancher_bas_id'
                      ? true
                      : false
                  "
                >
                  Plancher Bas
                </IconSort>
              </th>
              <th
                class="less-padding sticky-up offset"
                @click="sort('team_isolation_souffle_id')"
                title="Soufflé"
              >
                <IconSort
                  :sortDirection="sortDirection"
                  :column="
                    currentSort == 'team_isolation_souffle_id' ? true : false
                  "
                >
                  Soufflé
                </IconSort>
              </th>
              <th
                class="less-padding sticky-up offset"
                @click="sort('team_isolation_deroule_id')"
                title="Ballon Tampon"
              >
                <IconSort
                  :sortDirection="sortDirection"
                  :column="
                    currentSort == 'team_isolation_deroule_id' ? true : false
                  "
                >
                  Déroulé
                </IconSort>
              </th>
              <th
                class="less-padding sticky-up offset"
                @click="sort('team_isolation_sous_rampant_id')"
                title="Sous-Rampant"
              >
                <IconSort
                  :sortDirection="sortDirection"
                  :column="
                    currentSort == 'team_isolation_sous_rampant_id'
                      ? true
                      : false
                  "
                >
                  Sous-Rampant
                </IconSort>
              </th> -->
              <th
                class="less-padding sticky-up offset"
                @click="sort('team_isolations_id')"
                title="Isolations"
              >
                <IconSort
                  :sortDirection="sortDirection"
                  :column="currentSort == 'team_isolations_id' ? true : false"
                >
                  Isolations
                </IconSort>
              </th>
              <th
                class="less-padding sticky-up offset"
                @click="sort('team_isolation_ite_id')"
                title="ITE"
              >
                <IconSort
                  :sortDirection="sortDirection"
                  :column="
                    currentSort == 'team_isolation_ite_id' ? true : false
                  "
                >
                  ITE
                </IconSort>
              </th>
              <th
                class="less-padding sticky-up offset"
                @click="sort('team_isolation_iti_id')"
                title="ITI"
              >
                <IconSort
                  :sortDirection="sortDirection"
                  :column="
                    currentSort == 'team_isolation_iti_id' ? true : false
                  "
                >
                  ITI
                </IconSort>
              </th>
            </template>
            <!-- CUMAC_TYPE -->
            <!-- <template v-if="!hiddenColonneCumacType">
       
            </template> -->
            <!-- TYPE DES TRAVAUX -->
            <template v-if="!hiddenColonneTypeTravaux">
              <th
                class="less-padding sticky-up offset"
                @click="sort('chauff_dash')"
                title="Chauffage"
              >
                <IconSort
                  :sortDirection="sortDirection"
                  :column="currentSort == 'chauff_dash' ? true : false"
                >
                  Chauffage
                </IconSort>
              </th>
              <th
                class="less-padding sticky-up offset"
                @click="sort('vmc_dash')"
                title="Ballon"
              >
                <IconSort
                  :sortDirection="sortDirection"
                  :column="currentSort == 'vmc_dash' ? true : false"
                >
                  Ballon
                </IconSort>
              </th>
              <th
                class="less-padding sticky-up offset"
                @click="sort('ecs_dash')"
                title="Ballon Tampon"
              >
                <IconSort
                  :sortDirection="sortDirection"
                  :column="currentSort == 'ecs_dash' ? true : false"
                >
                  Ballon <br />
                  Tampon
                </IconSort>
              </th>
              <th
                class="less-padding sticky-up offset"
                @click="sort('iso_dash')"
                title="Isolation"
              >
                <IconSort
                  :sortDirection="sortDirection"
                  :column="currentSort == 'iso_dash' ? true : false"
                >
                  Isolation
                </IconSort>
              </th>
              <th
                class="less-padding sticky-up offset"
                @click="sort('ambiance_dash')"
                title="Ambiance"
              >
                <IconSort
                  :sortDirection="sortDirection"
                  :column="currentSort == 'ambiance_dash' ? true : false"
                >
                  Ambiance
                </IconSort>
              </th>
              <th
                class="less-padding sticky-up offset"
                @click="sort('ro_dash')"
                title="Chauffage Appoint"
              >
                <IconSort
                  :sortDirection="sortDirection"
                  :column="currentSort == 'ro_dash' ? true : false"
                >
                  Chauffage <br />Appoint
                </IconSort>
              </th>
              <th
                class="less-padding sticky-up offset"
                @click="sort('rr_dash')"
                title="Kit Bi-zone"
              >
                <IconSort
                  :sortDirection="sortDirection"
                  :column="currentSort == 'rr_dash' ? true : false"
                >
                  Kit Bi-zone
                </IconSort>
              </th>
              <th
                class="less-padding sticky-up offset"
                @click="sort('info_vmc_dash')"
                title="VMC"
              >
                <IconSort
                  :sortDirection="sortDirection"
                  :column="currentSort == 'info_vmc_dash' ? true : false"
                >
                  VMC
                </IconSort>
              </th>
            </template>

            <!-- les icon -->
            <th
              class="less-padding sticky-up offset"
              title="Dossier"
              @click="sort('dossier')"
            >
              <IconSort
                :sortDirection="sortDirection"
                :column="currentSort == 'dossier' ? true : false"
              >
                <font-awesome-icon
                  class="icon"
                  :icon="['fas', 'folder-open']"
                />
              </IconSort>
            </th>
            <th
              class="less-padding sticky-up offset"
              title="Photos"
              @click="sort('photo')"
            >
              <IconSort
                :sortDirection="sortDirection"
                :column="currentSort == 'photo' ? true : false"
              >
                <font-awesome-icon
                  class="icon-sort"
                  :icon="['fab', 'instagram']"
                />
              </IconSort>
            </th>
            <th
              class="less-padding sticky-up offset"
              title="Confirmation"
              @click="sort('confirmation')"
            >
              <IconSort
                :sortDirection="sortDirection"
                :column="currentSort == 'confirmation' ? true : false"
              >
                <WorkerIcon
                  class="icon-sort"
                  :height="'14px'"
                  :width="'12px'"
                  :fill="'#5e5e5e'"
                />
              </IconSort>
            </th>
            <!-- <th
              class="less-padding sticky-up offset"
              title="Confirmation commecial"
              @click="sort('confirmation_previsite')"
            >
              <IconSort
                :sortDirection="sortDirection"
                :column="currentSort == 'confirmation_previsite' ? true : false"
              >
                <font-awesome-icon
                  class="icon-sort"
                  :icon="['fas', 'user-tie']"
                />
              </IconSort>
            </th> -->
          </tr>
          <template v-if="getProjectsProjects && getProjectsProjects.length">
            <template v-for="(project, index) in getProjectsProjects">
              <!-- {{project}} -->
              <tr
                :style="{
                  height: '50px'
                }"
                :key="project.id + '-' + 1"
                :class="project.sub_stage_color"
              >
                <td :colspan="1" class="sticky less-padding">
                  <a-checkbox
                    v-model="project.checked"
                    @change="handleSelectedProject"
                  />
                </td>
                <!-- Créé le -->
                <td :colspan="1" class="sticky offset tagged-cell r">
                  <div class="main-td-content">
                    {{
                      project.create_date ? project.create_date : '--/--/----'
                    }}
                  </div>
                  <div class="sub-td-content">
                    à
                    {{ project.create_time ? project.create_time : '--:--:--' }}
                  </div>
                  <!-- <div class="extra-border"></div> -->
                </td>
                <!-- Derniere modification -->
                <td :colspan="1" class="sticky offset-2 tagged-cell r">
                  <div class="main-td-content">
                    {{
                      project.update_date ? project.update_date : '--/--/----'
                    }}
                  </div>
                  <div class="sub-td-content">
                    à
                    {{ project.update_time ? project.update_time : '--:--:--' }}
                  </div>
                  <div class="extra-border"></div>
                </td>
                <!-- Contact -->
                <td :colspan="1">
                  <div class="main-td-content">
                    {{
                      project.contact_full_name
                        ? project.contact_full_name
                        : '-'
                    }}
                  </div>
                  <div class="sub-td-content">
                    {{
                      project.contact_zipCode ? project.contact_zipCode : '-'
                    }}
                  </div>
                </td>
                <!-- valo -->
                <!-- <td :colspan="1" v-if="typeISO">
                  <div class="main-td-content">
                    {{ project.precar ? project.precarity : '-' }}
                  </div>
                  <div class="sub-td-content">
                    {{ project.zone ? project.zone : '-' }} /
                    {{
                      project.prj_type_heating ? project.prj_type_heating : '-'
                    }}
                  </div>
                </td> -->

                <!-- Source -->
                <td :colspan="1" v-if="!typePhotovoltaique">
                  <div class="main-td-content">
                    {{ project.source ? project.source : '-' }}
                  </div>
                  <div class="sub-td-content">
                    {{ project.comm_regie ? project.comm_regie : '-' }}
                  </div>
                </td>
                <!-- stade -->
                <td :colspan="1">
                  <div class="main-td-content">
                    {{ project.stage_name ? project.stage_name : '-' }}
                  </div>
                  <div class="sub-td-content">
                    {{ project.sub_stage_name ? project.sub_stage_name : '-' }}
                  </div>
                </td>
                <!-- sedentaire -->
                <!-- <td :colspan="1">
                  <div class="main-td-content">
                    {{
                      project.comm_sed_first_full_name
                        ? project.comm_sed_first_full_name
                        : '-'
                    }}
                  </div>
                  <div class="sub-td-content">
                    {{
                      project.comm_sed_team_name
                        ? project.comm_sed_team_name
                        : '-'
                    }}
                  </div>
                </td> -->
                <!-- Appel -->
                <!-- <td :colspan="1">
                  <div class="main-td-content">
                    {{
                      project.prj_appel_count ? project.prj_appel_count : '-'
                    }}
                  </div>
                </td> -->
                <!-- Prochaine activité -->
                <td v-if="typeCPF" :colspan="1">
                  <div class="main-td-content">
                    {{
                      project.prj_activity_recent
                        ? project.prj_activity_recent
                        : '-'
                    }}
                  </div>
                </td>
                <!-- closing le  -->
                <!-- <td :colspan="1">
                  <div class="main-td-content">
                    {{ project.prj_date_closing }}
                  </div>
                  <div class="sub-td-content" :title="project.date_closing_age">
                    {{
                      project.date_closing_age ? project.date_closing_age : '-'
                    }}
                  </div>
                </td> -->
                <template v-if="typeISO">
                  <!-- Commercial -->
                  <!-- <td :colspan="1">
                    <div class="main-td-content">
                      {{
                        project.comm_terr_full_name
                          ? project.comm_terr_full_name
                          : '-'
                      }}
                    </div>
                    <div class="sub-td-content">
                      {{
                        project.comm_terr_depots_name
                          ? project.comm_terr_depots_name
                          : '-'
                      }}
                    </div>
                  </td> -->
                  <!-- Pré-visiteur -->
                  <!-- <td :colspan="1">
                    <div class="main-td-content">
                      {{
                        project.comm_ite_full_name
                          ? project.comm_ite_full_name
                          : '-'
                      }}
                    </div>
                    <div class="sub-td-content">
                      {{
                        project.comm_ite_depots_name
                          ? project.comm_ite_depots_name
                          : '-'
                      }}
                    </div>
                  </td> -->
                  <!-- Rendez-vous le -->
                  <!-- <td
                    :colspan="1"
                    class="tagged-cell"
                    :class="{ l: project.statut_previsite }"
                  >
                    <div class="main-td-content">
                      {{
                        project.statut_previsite
                          ? project.statut_previsite
                          : '-'
                      }}
                    </div>
                    <div class="sub-td-content">
                      {{
                        project.date_previsite ? project.date_previsite : '-'
                      }}
                    </div>
                    <div
                      v-if="project.statut_previsite"
                      class="color-tag"
                      :class="project.statut_previsite"
                    ></div>
                  </td> -->

                  <!-- Poseur -->
                  <td :colspan="1" v-if="!typePhotovoltaique">
                    <div class="main-td-content">
                      {{
                        project.comm_ite_full_name
                          ? project.comm_ite_full_name
                          : '-'
                      }}
                    </div>
                    <div class="sub-td-content">
                      {{
                        project.comm_ite_depots_name
                          ? project.comm_ite_depots_name
                          : '-'
                      }}
                    </div>
                  </td>
                  <!--  visiteur technique  -->
                  <td :colspan="1">
                    <div class="main-td-content">
                      {{
                        project.visiteur_tech_full_name
                          ? project.visiteur_tech_full_name
                          : '-'
                      }}
                    </div>
                  </td>
                  <!-- Business Developper -->
                  <!-- <td :colspan="1">
                    <div class="main-td-content">
                      {{ '-' }}
                    </div>
                  </td> -->
                  <!-- chauffage -->
                  <td :colspan="1">
                    <div class="main-td-content">
                      {{
                        project.team_chauffage_name
                          ? project.team_chauffage_name
                          : '-'
                      }}
                    </div>
                  </td>
                  <!-- ISOLATION-->
                  <template v-if="!hiddenColonneTypeIsolation">
                    <!-- Plancher Bas -->
                    <!-- <td :colspan="1" class="cursor-main">
                      <div
                        class="main-td-content"
                        :title="
                          project.team_isolation_plancher_bas_name
                            ? project.team_isolation_plancher_bas_name
                            : '-'
                        "
                      >
                        {{
                          project.team_isolation_plancher_bas_name
                            ? project.team_isolation_plancher_bas_name
                            : '-'
                        }}
                      </div>
                    </td> -->
                    <!-- Soufflé -->
                    <!-- <td :colspan="1" class="cursor-main">
                      <div
                        class="main-td-content"
                        :title="
                          project.team_isolation_souffle_name
                            ? project.team_isolation_souffle_name
                            : '-'
                        "
                      >
                        {{
                          project.team_isolation_souffle_name
                            ? project.team_isolation_souffle_name
                            : '-'
                        }}
                      </div>
                    </td> -->
                    <!-- Déroulé -->
                    <!-- <td :colspan="1" class="cursor-main">
                      <div
                        class="main-td-content"
                        :title="
                          project.team_isolation_deroule_name
                            ? project.team_isolation_deroule_name
                            : '-'
                        "
                      >
                        {{
                          project.team_isolation_deroule_name
                            ? project.team_isolation_deroule_name
                            : '-'
                        }}
                      </div>
                    </td> -->
                    <!-- Sous-Rampant -->
                    <!-- <td :colspan="1" class="cursor-main">
                      <div
                        class="main-td-content"
                        :title="
                          project.team_isolation_sous_rampant_name
                            ? project.team_isolation_sous_rampant_name
                            : '-'
                        "
                      >
                        {{
                          project.team_isolation_sous_rampant_name
                            ? project.team_isolation_sous_rampant_name
                            : '-'
                        }}
                      </div>
                    </td> -->
                    <td :colspan="1" class="cursor-main">
                      <div
                        class="main-td-content"
                        :title="
                          project.team_name_isolations
                            ? project.team_name_isolations
                            : '-'
                        "
                      >
                        {{
                          project.team_name_isolations
                            ? project.team_name_isolations
                            : '-'
                        }}
                      </div>
                    </td>
                    <!-- ITE -->
                    <td :colspan="1" class="cursor-main">
                      <div
                        class="main-td-content"
                        :title="
                          project.team_isolation_ite_name
                            ? project.team_isolation_ite_name
                            : '-'
                        "
                      >
                        {{
                          project.team_isolation_ite_name
                            ? project.team_isolation_ite_name
                            : '-'
                        }}
                      </div>
                    </td>
                    <!-- ITI -->
                    <td :colspan="1" class="cursor-main">
                      <div
                        class="main-td-content"
                        :title="
                          project.team_isolation_iti_name
                            ? project.team_isolation_iti_name
                            : '-'
                        "
                      >
                        {{
                          project.team_isolation_iti_name
                            ? project.team_isolation_iti_name
                            : '-'
                        }}
                      </div>
                    </td>
                  </template>
                  <template v-else>
                    <td :colspan="1">
                      <div class="main-td-content">
                        {{ '-' }}
                      </div>
                    </td>
                  </template>
                  <!-- Docs -->
                  <td :colspan="1">
                    <div
                      class="color-cell"
                      :class="project.file_cat_color"
                    ></div>
                  </td>
                  <!-- octroi -->
                  <td :colspan="1">
                    <div
                      class="color-cell"
                      :class="project.lettre_acroi_color"
                    ></div>
                  </td>
                  <!-- hiddenColonneCumacType -->
                  <template>
                    <!-- Cumac  -->
                    <td :colspan="1">
                      <div class="main-td-content">
                        {{ project.cumac ? project.cumac : '-' }}
                      </div>
                    </td>
                    <!-- TYPE -->
                    <td :colspan="1" class="cursor-main">
                      <div class="main-td-content">
                        {{
                          project.type_renovation
                            ? project.type_renovation
                            : '-'
                        }}
                      </div>
                    </td>
                  </template>
                  <!-- <template v-else>
                    <td :colspan="1">
                      <div class="main-td-content">
                        {{ '-' }}
                      </div>
                    </td>
                  </template> -->
                  <!-- Travaux  -->
                  <!-- <td
                    :colspan="1"
                    class="tagged-cell"
                    :class="{ l: project.statut_travaux }"
                  >
                    <div class="main-td-content">
                      {{
                        project.statut_travaux ? project.statut_travaux : '-'
                      }}
                    </div>
                    <div class="sub-td-content">
                      {{ project.date_travaux ? project.date_travaux : '-' }}
                    </div>
                    <div
                      v-if="project.statut_travaux"
                      class="color-tag"
                      :class="project.statut_travaux"
                    ></div>
                  </td> -->

                  <!-- Type de travaux -->
                  <template v-if="!hiddenColonneTypeTravaux">
                    <!-- CHAUFFAGE -->
                    <td :colspan="1" class="cursor-main">
                      <TooltipSurfaceIso
                        :projects="project"
                        :type="'chauff_dash'"
                        :ref="'chauff_dash' + index"
                        v-if="
                          computedRef('chauff_dash' + index) &&
                            project.chauff_dash
                        "
                        :pop="computedRef('chauff_dash' + index)"
                        @close="functionClose('chauff_dash' + index)"
                      >
                      </TooltipSurfaceIso>
                      <div
                        class="main-td-content padding-tootiltip"
                        @mouseleave="hover('chauff_dash' + index)"
                        @mouseenter="hover('chauff_dash' + index)"
                      >
                        {{ project.chauff_dash }}
                      </div>
                    </td>
                    <!-- BALLON -->
                    <td :colspan="1" class="cursor-main">
                      <TooltipSurfaceIso
                        :projects="project"
                        :type="'vmc_dash'"
                        :ref="'vmc_dash' + index"
                        v-if="
                          computedRef('vmc_dash' + index) && project.vmc_dash
                        "
                        :pop="computedRef('vmc_dash' + index)"
                        @close="functionClose('vmc_dash' + index)"
                      >
                      </TooltipSurfaceIso>
                      <div
                        class="main-td-content padding-tootiltip"
                        @mouseleave="hover('vmc_dash' + index)"
                        @mouseenter="hover('vmc_dash' + index)"
                      >
                        {{ project.vmc_dash }}
                      </div>
                    </td>
                    <!-- Ballon Tampon -->
                    <td :colspan="1" class="cursor-main">
                      <TooltipSurfaceIso
                        :projects="project"
                        :type="'ecs_dash'"
                        :ref="'ecs_dash' + index"
                        v-if="
                          computedRef('ecs_dash' + index) && project.ecs_dash
                        "
                        :pop="computedRef('ecs_dash' + index)"
                        @close="functionClose('ecs_dash' + index)"
                      >
                      </TooltipSurfaceIso>
                      <div
                        class="main-td-content padding-tootiltip"
                        @mouseleave="hover('ecs_dash' + index)"
                        @mouseenter="hover('ecs_dash' + index)"
                      >
                        {{ project.ecs_dash }}
                      </div>
                    </td>
                    <!-- ISOLATION -->
                    <td :colspan="1" class="cursor-main">
                      <TooltipSurfaceIso
                        :projects="project"
                        :type="'iso_dash'"
                        :ref="'iso_dash' + index"
                        v-if="
                          computedRef('iso_dash' + index) && project.iso_dash
                        "
                        :pop="computedRef('iso_dash' + index)"
                        @close="functionClose('iso_dash' + index)"
                      >
                      </TooltipSurfaceIso>
                      <div
                        class="main-td-content padding-tootiltip"
                        @mouseleave="hover('iso_dash' + index)"
                        @mouseenter="hover('iso_dash' + index)"
                      >
                        {{ project.iso_dash }}
                      </div>
                    </td>
                    <!-- AMBIANCE -->
                    <td :colspan="1" class="cursor-main">
                      <TooltipSurfaceIso
                        :projects="project"
                        :type="'ambiance_dash'"
                        :ref="'ambiance_dash' + index"
                        v-if="
                          computedRef('ambiance_dash' + index) &&
                            project.ambiance_dash
                        "
                        :pop="computedRef('ambiance_dash' + index)"
                        @close="functionClose('ambiance_dash' + index)"
                      >
                      </TooltipSurfaceIso>
                      <div
                        class="main-td-content padding-tootiltip"
                        @mouseleave="hover('ambiance_dash' + index)"
                        @mouseenter="hover('ambiance_dash' + index)"
                      >
                        {{ project.ambiance_dash }}
                      </div>
                    </td>
                    <!-- Chauffage Appoint -->
                    <td :colspan="1" class="cursor-main">
                      <TooltipSurfaceIso
                        :projects="project"
                        :type="'ro_dash'"
                        :ref="'ro_dash' + index"
                        v-if="computedRef('ro_dash' + index) && project.ro_dash"
                        :pop="computedRef('ro_dash' + index)"
                        @close="functionClose('ro_dash' + index)"
                      >
                      </TooltipSurfaceIso>
                      <div
                        class="main-td-content padding-tootiltip"
                        @mouseleave="hover('ro_dash' + index)"
                        @mouseenter="hover('ro_dash' + index)"
                      >
                        {{ project.ro_dash }}
                      </div>
                    </td>
                    <!-- Kit Bi-zone -->
                    <td :colspan="1" class="cursor-main">
                      <TooltipSurfaceIso
                        :projects="project"
                        :type="'rr_dash'"
                        :ref="'rr_dash' + index"
                        v-if="computedRef('rr_dash' + index) && project.rr_dash"
                        :pop="computedRef('rr_dash' + index)"
                        @close="functionClose('rr_dash' + index)"
                      >
                      </TooltipSurfaceIso>
                      <div
                        class="main-td-content padding-tootiltip"
                        @mouseleave="hover('rr_dash' + index)"
                        @mouseenter="hover('rr_dash' + index)"
                      >
                        {{ project.rr_dash }}
                      </div>
                    </td>
                    <!-- vmc -->
                    <td :colspan="1" class="cursor-main">
                      <TooltipSurfaceIso
                        :projects="project"
                        :type="'info_vmc_dash'"
                        :ref="'info_vmc_dash' + index"
                        v-if="
                          computedRef('info_vmc_dash' + index) &&
                            project.info_vmc_dash
                        "
                        :pop="computedRef('info_vmc_dash' + index)"
                        @close="functionClose('info_vmc_dash' + index)"
                      >
                      </TooltipSurfaceIso>
                      <div
                        class="main-td-content padding-tootiltip"
                        @mouseleave="hover('info_vmc_dash' + index)"
                        @mouseenter="hover('info_vmc_dash' + index)"
                      >
                        {{ project.info_vmc_dash }}
                      </div>
                    </td>
                  </template>
                  <template v-else>
                    <td :colspan="1">
                      <div class="main-td-content">
                        {{ '-' }}
                      </div>
                    </td>
                  </template>
                  <!-- Dossier -->
                  <td :colspan="1" class="less-padding">
                    <a-checkbox
                      class="dossier-checkbox"
                      v-model="project.dossier"
                      :readonly="readonly"
                      @change="updateDossier($event, 'dossier', project)"
                    />
                  </td>
                  <td :colspan="1" class="less-padding">
                    <a-checkbox
                      class="dossier-checkbox"
                      v-model="project.photo"
                      :readonly="readonly"
                      @change="updateDossier($event, 'photo', project)"
                    />
                  </td>
                  <td :colspan="1" class="less-padding">
                    <a-checkbox
                      class="dossier-checkbox"
                      v-model="project.confirmation"
                      :readonly="readonly"
                      @change="updateDossier($event, 'confirmation', project)"
                    />
                  </td>
                  <!-- <td :colspan="1" class="less-padding">
                    <a-checkbox
                      class="dossier-checkbox"
                      v-model="project.confirmation_previsite"
                      :readonly="readonly"
                      @change="
                        updateDossier($event, 'confirmation_previsite', project)
                      "
                    />
                  </td> -->
                </template>
                <template v-if="typeCPF">
                  <td :colspan="1">
                    <div class="main-td-content">
                      {{ project.budget_reel ? project.budget_reel : '-'
                      }}<span v-if="project.budget_reel"> €</span>
                    </div>
                  </td>
                  <td :colspan="1">
                    <div class="main-td-content">
                      {{ project.progression ? project.progression : '-' }}
                    </div>
                  </td>
                  <td :colspan="1">
                    <div class="main-td-content">
                      {{
                        project.type_formation ? project.type_formation : '-'
                      }}
                    </div>
                  </td>
                  <td :colspan="1">
                    <div class="main-td-content">
                      {{
                        project.date_debut_formation
                          ? project.date_debut_formation
                          : '-'
                      }}
                    </div>
                  </td>
                  <td :colspan="1">
                    <div class="main-td-content">
                      {{
                        project.date_fin_formation
                          ? project.date_fin_formation
                          : '-'
                      }}
                    </div>
                  </td>
                </template>
              </tr>
            </template>
          </template>
          <div
            v-else-if="!getProjectsProcessing && !getProjectCountProcessing"
            class="no-project"
          >
            Aucun projet trouvé
          </div>
        </table>
      </div>
    </div>
    <!-------------------------------- TABLE PROJET TH 164-------------------------------------------->
    <!-- <ProjectTH164
      v-if="currentType && currentType.id == 112"
      :getProjectsProjects="getProjectsProjects"
      :currentType="currentType"
      :selectedVues="selectedVues"
      :readonly="readonly"
    ></ProjectTH164> -->
    <!--  Filtre PROJET  -->
    <!-- MODAL SAVE FILTERS -->
    <b-modal
      id="modal-save-filters"
      title="Enregistrer un filtre"
      no-close-on-backdrop
      ref="modal-save-filters"
      :hide-footer="true"
    >
      <form @submit.prevent="savefilters(nameFilters, formatedCustomFilters)">
        <b-form-group label="Nom">
          <b-form-input v-model="nameFilters"></b-form-input>
        </b-form-group>
        <!-- <div class="custom-filters">
          <div
            class="filter-item-container"
            v-for="(item, index) in formatedCustomFilters"
            :key="index"
          >
            <div class="filter-item">
              <span class="label">{{ item.keyLabel }} : </span>
              <span class="ml-1">{{ item.valueLabel }}</span>
            </div>
          </div>
        </div> -->

        <div class="message">
          <div v-if="getFiltersProjectTHLoading" class="loading">
            Chargement en cours...
          </div>
          <div v-if="getFiltersProjectTHError" class="error">
            <ul v-if="Array.isArray(getFiltersProjectTHError)">
              <li v-for="(e, index) in getFiltersProjectTHError" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getFiltersProjectTHError }}</span>
          </div>
        </div>
        <div class="form-actions">
          <b-button
            variant="outline-secondary"
            @click="hideModal('modal-save-filters')"
          >
            Annuler
          </b-button>
          <b-button variant="success" type="submit"> Valider </b-button>
        </div>
      </form>
    </b-modal>
    <!-- MODAL SELECT FILTERS  -->
    <b-modal
      id="modal-select-filters"
      title="Choisir un filtre"
      no-close-on-backdrop
      ref="modal-select-filters"
      :hide-footer="true"
    >
      <b-form-group label="Filtre">
        <multiselect
          v-model="selectedSaveFilters"
          :options="getSaveFilters"
          placeholder="Rechercher un filtre"
          :multiple="false"
          :close-on-select="true"
          :clear-on-select="true"
          :allow-empty="false"
          :preserve-search="true"
          label="name"
          track-by="id"
          :preselect-first="false"
          @input="changeSelectFilter"
        >
          <span slot="noResult">Aucun filtre trouvé.</span>
          <span slot="noOptions">Aucun filtre trouvé.</span>
        </multiselect>
      </b-form-group>
      <div
        v-if="selectFiler && loading"
        class="init-loading three-dots-loading"
      >
        Chargement en cours
      </div>
      <div v-if="selectFiler" class="read-filter">
        <div v-for="(item, index) in conditionsAnd" :key="'and' + index">
          <span class="bold">{{ item.label_Key }} : </span
          >{{ item.list.join(',') }}
        </div>
        <div v-for="(item, index) in conditionsOr" :key="'or' + index">
          <span class="bold">{{ item.label_Key }} : </span
          >{{ item.list.join(' ou ') }}
        </div>
      </div>
      <div class="delete-filter" v-if="getSaveFilters && getSaveFilters.length">
        <a
          href="#"
          @click.prevent="handleDeleteSaveFilters(selectedSaveFilters)"
        >
          <font-awesome-icon class="mr-1" icon="trash" />
          Supprimer ce filtre
        </a>
      </div>
      <div class="message">
        <div v-if="getProjectsProcessing" class="loading">
          Chargement en cours...
        </div>
        <div v-if="getProjectsError" class="error">
          <ul v-if="Array.isArray(getProjectsError)">
            <li v-for="(e, index) in getProjectsError" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ getProjectsError }}</span>
        </div>
      </div>
      <div class="form-actions">
        <b-button
          variant="outline-secondary"
          @click="hideModal('modal-select-filters')"
        >
          Annuler
        </b-button>
        <b-button
          variant="success"
          @click="addFilterCreer(selectedSaveFilters)"
        >
          Valider
        </b-button>
      </div>
    </b-modal>
    <portal to="project-modal">
      <project-modal
        v-if="
          getProjectsExtraProject &&
            getProjectsExtraProject.checked &&
            getProjectsExtraProject.checked == true &&
            !transition
        "
        key="side-modal"
        :data="getProjectsExtraProject"
        :readonly="readonly"
        @refreshPipeline="refreshPipeline"
        @closeModal="closeModal"
        :type="currentType"
      ></project-modal>
      <project-modal
        v-else-if="
          checkedProjects && checkedProjects.length == 1 && !transition
        "
        key="side-modal"
        :data="checkedProjects[0]"
        :readonly="readonly"
        @refreshPipeline="refreshPipeline"
        @closeModal="closeModal"
        :processing="getProjectsProcessing"
        :type="currentType"
      ></project-modal>

      <multi-project-modal
        v-else-if="checkedProjects.length > 1 && visibilityVoirModalMultiProjet"
        key="multi-modal"
        :data="checkedProjects"
        :type="currentType"
        @close="handleCloseMultiProject"
      ></multi-project-modal>
    </portal>
    <portal to="new-project-modal">
      <new-project-modal
        v-if="newProjectState === true"
        @close="newProjectState = false"
        @created="handleCreateProject"
        :currentType="currentType"
      ></new-project-modal>
    </portal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Multiselect from 'vue-multiselect'
export default {
  components: {
    NewProjectModal: () => import('./modals/NewProjectModal.vue'),
    ProjectModal: () => import('./modals/ProjectModal.vue'),
    MultiProjectModal: () => import('./modals/MultiProjectModal.vue'),
    WorkerIcon: () => import('@/components/ui/WorkerIcon.vue'),
    // TooltipCommercialSed: () => import('./filters/TooltipCommercialSed.vue'),
    // TooltipCommercialTerrain: () =>
    //   import('./filters/TooltipCommercialTerrain.vue'),
    TooltipContactFilter: () => import('./filters/TooltipContactFilter.vue'),
    // TooltipCreditFilter: () => import('./filters/TooltipCreditFilter.vue'),
    TooltipDateFilter: () => import('./filters/TooltipDateFilter.vue'),
    // TooltipDossierFilter: () => import('./filters/TooltipDossierFilter.vue'),
    // TooltipEcoTechnicien: () => import('./filters/TooltipEcoTechnicien.vue'),
    TooltipPoseurFilter: () => import('./filters/TooltipPoseurFilter.vue'),
    TooltipVisiteurTechnique: () =>
      import('./filters/TooltipVisiteurTechnique.vue'),
    // TooltipPreVisiteFilter: () =>
    //   import('./filters/TooltipPreVisiteFilter.vue'),
    // TooltipPrimRenovFilter: () =>
    //   import('./filters/TooltipPrimRenovFilter.vue'),
    TooltipSource: () => import('./filters/TooltipSource.vue'),
    TooltipStadeFilter: () => import('./filters/TooltipStadeFilter.vue'),
    // TooltipTravauxFilter: () => import('./filters/TooltipTravauxFilter.vue'),
    TooltipType: () => import('./filters/TooltipType.vue'),
    TooltipCumacFilter: () => import('./filters/TooltipCumacFilter.vue'),
    TooltipDocsFilter: () => import('./filters/TooltipDocsFilter.vue'),
    // TooltipValorisationFilter: () =>
    //   import('./filters/TooltipValorisationFilter.vue'),
    TooltipSurfaceIso: () => import('./filters/TooltipSurfaceIso.vue'),
    Multiselect,
    ACheckbox: () => import('@/components/ui/ACheckbox'),
    LoadingComponent: () => import('@/components/ui/LoadingComponent'),
    IconSort: () => import('@/components/ui/IconSort'),
    FilterProjetTH: () => import('@/components/ui/FilterProjetTH')
    // ProjectTH164: () => import('@/views/Projects/ProjectTH164')
  },
  data() {
    return {
      initLoading: true,
      currentType: null,
      currentFilterPipeline: null,
      pipelineFilters: [],
      customFilters: [],
      formatedCustomFilters: [],
      filters: [],
      conditions: {},
      selectAll: false,
      newProjectState: false,
      currentPage: 1,
      blockNavigation: false,
      currentPipelineIndex: 0,
      proccessingPipelineFilter: true,
      synchronizers: [],
      transition: false,
      sortDirection: 'asc',
      currentSort: 'creation_date',
      nameFilters: null,
      selectFiler: false,
      conditionsOr: [],
      conditionsAnd: [],
      selectedSaveFilters: null,
      currentFilters: null,
      selectedFilterId: null,
      notSave: true,
      loading: false,
      selectedVues: { id: 0, label: ' Tout le tableau, toutes les colonnes' },
      vueTable: [
        { id: 0, label: ' Tout le tableau, toutes les colonnes' },
        { id: 1, label: 'BAO & DEVIS' },
        { id: 2, label: 'SUIVI CHANTIER' },
        { id: 3, label: 'COFRAC & VALO' },
        ////
        { id: 4, key: 'relev_incomplet', label: 'Relevés incomplet' },
        { id: 5, key: 'relev_a_env', label: 'Relevés à envoyer' },
        { id: 6, key: 'bao_a_faire', label: 'Etude BAO à faire' },
        { id: 7, key: 'devis_a_edit', label: 'Devis à éditer' },
        { id: 8, key: 'devis_a_sign', label: 'Devis à signer' },
        { id: 9, key: 'pose_cours', label: 'Pose en cours' },
        { id: 10, key: 'pose_termin', label: 'Pose terminée' },
        { id: 11, key: 'chantier_a_planif', label: 'Chantier à planifier' },
        { id: 12, key: 'chantier_planif', label: 'Poses planifiées' }
      ],
      messageUplaodExcel: false,
      conditionsFiltreProjetTH: {
        and: [],
        or: []
      },
      filterToUpdate: null,
      nameFilterTH: null,
      selectedNameFilterTH: null,
      conditionFilterTH: false,
      errorFilterTH: null,
      filterTHToSelected: null,
      conditionsTH: null,
      filterTHToDelete: null,
      // conditionsUpdateTh: null,
      formatedCustomFiltersTH: [],
      disabled: false,
      perPage: 30,
      TousLingeTH: 0,
      pageOptions: ['', 10, 30, 60, 100],
      filterPrincipal: false,
      ref_tab: [],
      hiddenColonneTypeTravaux: true,
      hiddenColonneTypeIsolation: true,
      hiddenColonneCumacType: true
    }
  },
  methods: {
    ...mapActions([
      'updateProjectTH',
      'fetchProjetsTypes',
      'fetchProjects',
      'fetchPipelinesPipelines',
      'updateProject',
      'fetchUsersCommercialSedentaire',
      'fetchUsersCommercialsIte',
      'fetchUsersRespPlan',
      'fetchUsersTechniciens',
      'fetchProjectFields',
      'fetchContactFields',
      'fetchProjectSource',
      'fetchSingleProject',
      'updateProjetsTypesCounter',
      'updatePipelinesCounter',
      'exportProjectFile',
      'saveFilter',
      'fetchSaveFilters',
      'deleteSaveFilters',
      'fetchProjectCampagne',
      'fetchProjectDepot',
      'createProjectTh',
      'uploadFileExcel',
      'fetchFieldsToFilterTH',
      'fetchOperatorsToFieldsTH',
      'ajouterFilterTH',
      'updateFiltreTH',
      'resetErrorFilterTH',
      'fetchListFiltresTH',
      'deleteFilterTH',
      'selectedFilterProjectTh'
    ]),
    clickHiddenColonneTypeTravaux(e) {
      this.hiddenColonneTypeTravaux = e ? false : true
      if (
        this.conditions &&
        this.conditions.conditions &&
        this.conditions.conditions.length
      ) {
        this.fetchProjects({
          per_page: 50,
          page: this.currentPage,
          sort_by_desc: this.currentSort,
          type_id: this.currentType.id,
          conditions: this.conditions,
          filter_id:
            JSON.parse(localStorage.getItem('filterPrincipalId')) &&
            JSON.parse(localStorage.getItem('filterPrincipalId')).id
              ? JSON.parse(localStorage.getItem('filterPrincipalId')).id
              : null,
          has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1,
          has_filiales_isolations:
            !this.hiddenColonneTypeIsolation == false ? 0 : 1
        })
      } else {
        this.fetchProjects({
          per_page: 50,
          page: this.currentPage,
          sort_by_desc: this.currentSort,
          type_id: this.currentType.id,
          filter_id:
            JSON.parse(localStorage.getItem('filterPrincipalId')) &&
            JSON.parse(localStorage.getItem('filterPrincipalId')).id
              ? JSON.parse(localStorage.getItem('filterPrincipalId')).id
              : null,
          has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1,
          has_filiales_isolations:
            !this.hiddenColonneTypeIsolation == false ? 0 : 1
        })
      }
    },
    clickHiddenColonneTypeIsolation(e) {
      this.hiddenColonneTypeIsolation = e ? false : true
      if (
        this.conditions &&
        this.conditions.conditions &&
        this.conditions.conditions.length
      ) {
        this.fetchProjects({
          per_page: 50,
          page: this.currentPage,
          sort_by_desc: this.currentSort,
          type_id: this.currentType.id,
          conditions: this.conditions,
          filter_id:
            JSON.parse(localStorage.getItem('filterPrincipalId')) &&
            JSON.parse(localStorage.getItem('filterPrincipalId')).id
              ? JSON.parse(localStorage.getItem('filterPrincipalId')).id
              : null,
          has_filiales_isolations:
            !this.hiddenColonneTypeIsolation == false ? 0 : 1,
          has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1
        })
      } else {
        this.fetchProjects({
          per_page: 50,
          page: this.currentPage,
          sort_by_desc: this.currentSort,
          type_id: this.currentType.id,
          filter_id:
            JSON.parse(localStorage.getItem('filterPrincipalId')) &&
            JSON.parse(localStorage.getItem('filterPrincipalId')).id
              ? JSON.parse(localStorage.getItem('filterPrincipalId')).id
              : null,
          has_filiales_isolations:
            !this.hiddenColonneTypeIsolation == false ? 0 : 1,
          has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1
        })
      }
    },
    async clickHiddenColonneCumacType(e) {
      this.hiddenColonneCumacType = e ? false : true
      await this.fetchProjects({
        per_page: 50,
        page: this.currentPage,
        sort_by_desc: this.currentSort,
        type_id: this.currentType.id,
        conditions: this.conditions,
        filter_id:
          JSON.parse(localStorage.getItem('filterPrincipalId')) &&
          JSON.parse(localStorage.getItem('filterPrincipalId')).id
            ? JSON.parse(localStorage.getItem('filterPrincipalId')).id
            : null,
        has_cumac_type_renovation:
          !this.hiddenColonneCumacType == false ? 0 : 1,
        has_filiales_isolations:
          !this.hiddenColonneTypeIsolation == false ? 0 : 1,
        has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1
      })
    },
    hover(type) {
      this.ClickTootltip(type)
    },
    ClickTootltip(e) {
      this.ref_tab = []
      const found = this.ref_tab.find(item => item == e)
      if (found) {
        this.ref_tab = []
      } else {
        this.ref_tab.push(e)
      }
    },
    functionClose() {
      this.ref_tab = []
    },
    // projet TH Filtre
    // show modal
    async showModalSelectedFilterTH() {
      this.disabled = true
      this.resetModalSelectedFilterTH()
      this.fetchListFiltresTH({
        exclude: 'conditions',
        for_visibility: 1
        // th_prj: 1
      })
      this.$refs['selected_Filter_th'].show()
    },
    async showModalAddFilterTH() {
      if (!this.getFieldsTHToFiltre.length) {
        await this.fetchFieldsToFilterTH()
      }
      this.$refs['add_Filter_th'].show()
    },
    showModalUpdatedFilterTH() {
      this.filterToUpdate = { ...this.filterTHToSelected }
      this.filterToUpdate.nameFilterTH = this.filterTHToSelected.name
      this.disabled = false
    },
    showModalDeletedFilterTH(filter) {
      this.$refs['deleted_Filter_th'].show()
      this.filterTHToDelete = filter
    },
    //
    async selectedFilterTHCreer(filter, reset) {
      if (filter && filter.id) {
        this.disabled = true
        this.loadingFilter = true
        this.selectedNameFilterTH = { id: filter.id, name: filter.name }
        this.conditionFilterTH = true
        this.filterTHToSelected = { ...filter }
        let conditionAnd = []
        let conditionOr = []
        // routes des champs des table
        if (!this.getFieldsTHToFiltre.length) {
          await this.fetchFieldsToFilterTH()
        }
        // CONDITION AND
        if (this.filterTHToSelected.conditions.and.length) {
          for (
            let index = 0;
            index < this.filterTHToSelected.conditions.and.length;
            index++
          ) {
            let and = this.filterTHToSelected.conditions.and[index]
            let fieldKeyAnd = this.$options.filters.formatKey(and.field_key)
              ? this.$options.filters.formatKey(and.field_key)
              : and.field_key

            if (this.getFieldsTHToFiltre && this.getFieldsTHToFiltre.length) {
              for (
                let index = 0;
                index < this.getFieldsTHToFiltre.length;
                index++
              ) {
                const element = this.getFieldsTHToFiltre[index]
                // routes des operateurs
                if (element && element.key == fieldKeyAnd) {
                  // routes des operateurs
                  if (!this.getOperatorTHToFiltre[element.key]) {
                    await this.fetchOperatorsToFieldsTH({
                      field_id: element.id,
                      field_key: element.key
                    })
                  }
                }
              }
              if (
                this.getOperatorTHToFiltre[fieldKeyAnd].operators &&
                this.getOperatorTHToFiltre[fieldKeyAnd].operators.length
              ) {
                const field = this.getFieldsTHToFiltre.find(
                  e => e.key == fieldKeyAnd
                )
                const operator = this.getOperatorTHToFiltre[
                  fieldKeyAnd
                ].operators.find(e => e.key == and.operator)
                if (
                  and.field_key == 'type_renovation' &&
                  and.value == "Ma prime renov''"
                ) {
                  and.value = "Ma prime renov'"
                }
                conditionAnd.push({
                  field_key: field,
                  object: and.object.value,
                  operator: operator,
                  value: and.value
                })
              }
            }
          }
        }
        //CONDITION OU
        if (this.filterTHToSelected.conditions.or.length) {
          for (
            let index = 0;
            index < this.filterTHToSelected.conditions.or.length;
            index++
          ) {
            let or = this.filterTHToSelected.conditions.or[index]
            let fieldKeyOr = this.$options.filters.formatKey(or.field_key)
              ? this.$options.filters.formatKey(or.field_key)
              : or.field_key
            if (this.getFieldsTHToFiltre && this.getFieldsTHToFiltre.length) {
              for (
                let index = 0;
                index < this.getFieldsTHToFiltre.length;
                index++
              ) {
                const element = this.getFieldsTHToFiltre[index]
                // routes des operateurs
                if (element && element.key == fieldKeyOr) {
                  // routes des operateurs
                  if (!this.getOperatorTHToFiltre[element.key]) {
                    await this.fetchOperatorsToFieldsTH({
                      field_id: element.id,
                      field_key: element.key
                    })
                  }
                }
              }
              if (
                this.getOperatorTHToFiltre[fieldKeyOr].operators &&
                this.getOperatorTHToFiltre[fieldKeyOr].operators.length
              ) {
                const field = this.getFieldsTHToFiltre.find(
                  e => e.key == fieldKeyOr
                )
                const operator = this.getOperatorTHToFiltre[
                  fieldKeyOr
                ].operators.find(e => e.key == or.operator)
                if (
                  or.field_key == 'type_renovation' &&
                  or.value == "Ma prime renov''"
                ) {
                  or.value = "Ma prime renov'"
                }
                conditionOr.push({
                  field_key: field,
                  object: or.object.value,
                  operator: operator,
                  value: or.value
                })
              }
            }
          }
        }
        this.$nextTick(() => {
          this.conditionsTH = {
            and: conditionAnd,
            or: conditionOr
          }
          if (reset == 'reset') {
            this.formatedCustomFiltersTH = this.conditionsTH
          }
        })
        setTimeout(() => {
          this.loadingFilter = false
        }, 500)
      }
    },
    resetModalAddFilterTH() {
      this.nameFilterTH = null
      this.conditionsFiltreProjetTH = {
        and: [],
        or: []
      }
      this.errorFilterTH = null
      this.resetErrorFilterTH()
    },
    retourModalSelectedFilterTH(filter) {
      if (this.getFiltersProjectTH && this.getFiltersProjectTH.length) {
        for (let index = 0; index < this.getFiltersProjectTH.length; index++) {
          let condition = this.getFiltersProjectTH[index]
          if (condition.id == filter.id) {
            this.selectedFilterTHCreer(condition, 'reset')
          }
        }
      }
      this.resetModalSelectedFilter()
    },
    resetModalSelectedFilter() {
      this.nameFilterTH = null
      this.disabled = true
      this.filterToUpdate = null
      this.errorFilterTH = null
      this.resetErrorFilterTH()
    },
    resetModalDeleteFilterTH() {
      this.errorFilterTH = null
      this.resetErrorFilterTH()
    },
    resetModalSelectedFilterTH() {
      this.selectedNameFilterTH = null
      this.errorFilterTH = null
      this.resetErrorFilterTH()
    },
    validateFilterTH(filter, condition) {
      const validate = {
        status: 'success',
        errors: []
      }
      for (let index = 0; index < condition.and.length; index++) {
        const element = condition.and[index]
        if (!element.field_key || !element.operator) {
          validate.status = 'error'
          validate.errors.push(
            'Tous les champs sont obligatoires pour la condition ET'
          )
        }
        if (
          element.operator.key != 'IS NULL' &&
          element.operator.key != 'IS NOT NULL' &&
          !element.value
        ) {
          validate.status = 'error'
          let msg = ''
          if (element.field_key && element.operator) {
            msg =
              'la valeur de champ de ' +
              element.field_key.name +
              ' est obligatoire pour la condition ET'
            validate.errors.push(msg)
          }
        }
      }
      for (let index = 0; index < condition.or.length; index++) {
        const element = condition.or[index]
        if (!element.field_key || !element.operator) {
          validate.status = 'error'
          validate.errors.push(
            'Tous les champs sont obligatoires pour la condition OU'
          )
        }
        if (
          element.operator.key != 'IS NULL' &&
          element.operator.key != 'IS NOT NULL' &&
          !element.value
        ) {
          validate.status = 'error'
          let msg = ''
          if (element.field_key && element.operator) {
            msg = msg =
              'la valeur de champ de ' +
              element.field_key.name +
              ' est obligatoire pour la condition OU'
            validate.errors.push(msg)
          }
        }
      }
      if (!filter.name) {
        validate.status = 'error'
        validate.errors.push('Le nom est obligatoire')
      }
      if (!filter.conditions.conditions.length) {
        validate.status = 'error'
        validate.errors.push('Ajouter au minimum une condition')
      }
      return validate
    },
    async addFilterTH() {
      const conditions = []
      if (this.conditionsFiltreProjetTH.or.length) {
        conditions.push({
          glue: 'or',
          conditions: this.conditionsFiltreProjetTH.or.map(or => {
            let value = or.value ? or.value.id : null
            if (or.type == 'date' || or.type == 'text') {
              value = or.value ? or.value : null
            }
            return {
              field_key: or.field_key.key,
              object: or.object.value,
              operator: or.operator.key,
              value: value
            }
          })
        })
      }
      if (this.conditionsFiltreProjetTH.and.length) {
        conditions.push({
          glue: 'and',
          conditions: this.conditionsFiltreProjetTH.and.map(and => {
            let value = and.value ? and.value.id : null
            if (and.type == 'date' || and.type == 'text') {
              value = and.value ? and.value : null
            }
            return {
              field_key: and.field_key.key,
              object: and.object.value,
              operator: and.operator.key,
              value: value
            }
          })
        })
      }
      const payload = {
        name: this.nameFilterTH,
        type: 'project',
        is_for_visibility: 0,
        // th_prj: 1,
        conditions: {
          glue: 'and',
          conditions: conditions
        }
      }
      const validate = this.validateFilterTH(
        payload,
        this.conditionsFiltreProjetTH
      )
      if (validate.status === 'error') {
        this.errorFilterTH = validate.errors
        return
      }
      const response = await this.ajouterFilterTH(payload)
      this.errorFilterTH = null
      if (response) {
        this.hideModal('add_Filter_th')
        this.resetModalAddFilterTH()
        this.selectedFilterTHCreer(this.getFiltersProjectTH[0])
      }
    },
    async modifierFilterTH() {
      const conditions = []
      if (this.conditionsTH.or.length) {
        conditions.push({
          glue: 'or',
          conditions: this.conditionsTH.or.map(or => {
            let value = or.value ? or.value.id : null
            if (or.type == 'date' || or.type == 'text') {
              value = or.value ? or.value : null
            }
            return {
              field_key: or.field_key.key,
              object: or.object.value,
              operator: or.operator.key,
              value: value
            }
          })
        })
      }
      if (this.conditionsTH.and.length) {
        conditions.push({
          glue: 'and',
          conditions: this.conditionsTH.and.map(and => {
            let value = and.value ? and.value.id : null
            if (and.type == 'date' || and.type == 'text') {
              value = and.value ? and.value : null
            }
            return {
              field_key: and.field_key.key,
              object: and.object.value,
              operator: and.operator.key,
              value: value
            }
          })
        })
      }
      const payload = {
        id: this.filterToUpdate.id,
        name: this.filterToUpdate.nameFilterTH,
        type: 'project',
        conditions: {
          glue: 'and',
          conditions: conditions
        }
      }
      const validate = this.validateFilterTH(payload, this.conditionsTH)
      if (validate.status === 'error') {
        this.errorFilterTH = validate.errors
        return
      }
      const response = await this.updateFiltreTH(payload)
      this.errorFilterTH = null
      if (response) {
        this.resetModalSelectedFilter()
        this.selectedNameFilterTH = { id: payload.id, name: payload.name }
      }
    },
    async supprimerFilterTH() {
      if (this.filterTHToDelete) {
        const response = await this.deleteFilterTH(this.filterTHToDelete)
        if (response) {
          this.hideModal('deleted_Filter_th')
          this.selectedNameFilterTH = null
          if (
            this.filterIdSelected &&
            this.filterTHToDelete &&
            this.filterIdSelected.id == this.filterTHToDelete.id
          ) {
            this.deleteCustomFilterTH()
          }
        }
      }
    },
    async selectionnerFilterTH(filter) {
      this.currentPage = 1
      // this.perPage = 30
      const response = await this.selectedFilterProjectTh({
        filter_id: filter.id,
        type_id: this.currentType.id,
        has_filiales_isolations:
          !this.hiddenColonneTypeIsolation == false ? 0 : 1,
        has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1,
        has_cumac_type_renovation: 1
        // per_page: this.perPage ? this.perPage : 30,
        // page: this.currentPage
      })
      this.filterIdSelected = filter
      if (response) {
        this.hideModal('selected_Filter_th')
        this.resetModalSelectedFilterTH()
        localStorage.removeItem('filter')
        localStorage.removeItem('filterCreer')
        this.formatedCustomFilters = []
        this.formatedCustomFiltersTH = this.conditionsTH
        localStorage.setItem(
          'filterPrincipal',
          JSON.stringify(this.formatedCustomFiltersTH)
        )
        localStorage.setItem(
          'filterPrincipalId',
          JSON.stringify(this.filterIdSelected)
        )
        this.filterPrincipal = true
      }
    },
    async deleteCustomFilterTH() {
      const response = await this.fetchProjects({
        per_page: 50,
        page: this.currentPage,
        sort_by_desc: this.currentSort,
        type_id: this.currentType.id,
        has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1,
        has_filiales_isolations:
          !this.hiddenColonneTypeIsolation == false ? 0 : 1
      })
      if (response) {
        localStorage.removeItem('filterPrincipal')
        localStorage.removeItem('filterPrincipalId')
        this.filterPrincipal = false
        this.customFilters = []
        this.formatedCustomFilters = []
        this.formatedCustomFiltersTH = []
      }
    },
    resetCustomFilterTH() {},
    //
    // async selectedFilterTh(vue) {
    //   this.formatedCustomFiltersTH = {}
    //   this.filterIdSelected = null
    //   this.currentPage = 1
    //   // this.perPage = 30
    //   // this.TousLingeTH = 0
    //   if (vue && vue.key) {
    //     await this.fetchProjects({
    //       page: this.currentPage,
    //       display_all: 1,
    //       sort_by: this.currentSort,
    //       type_id: this.currentType.id,
    //       keyVue: vue.key,
    //       categories: 1,
    //       refresh: false
    //     })
    //   } else if (vue && vue.keyVue) {
    //     await this.fetchProjects({
    //       page: this.currentPage,
    //       display_all: 1,
    //       type_id: this.currentType.id,
    //       categories: 1,
    //       refresh: false
    //     })
    //   } else {
    //     await this.fetchProjects({
    //       page: this.currentPage,
    //       per_page: 30,
    //       type_id: this.currentType.id,
    //       categories: 1,
    //       refresh: false
    //     })
    //   }
    // },
    // async updateChampProjectTH(project) {
    //   const response = await this.updateProjectTH(project)
    //   if (response) {
    //     if (
    //       this.selectedVues &&
    //       this.selectedVues.id &&
    //       this.selectedVues.key &&
    //       [4, 5, 6, 7, 8, 9, 10, 11, 12].includes(this.selectedVues.id)
    //     ) {
    //       if (
    //         ((this.selectedVues.id == 4 || this.selectedVues.id == 5) &&
    //           project.field == 'statut_relv_info') ||
    //         (this.selectedVues.id == 6 && project.field == 'statut_bao') ||
    //         ((this.selectedVues.id == 7 || this.selectedVues.id == 8) &&
    //           project.field == 'label_etat_devis') ||
    //         ((this.selectedVues.id == 9 ||
    //           this.selectedVues.id == 10 ||
    //           this.selectedVues.id == 11) &&
    //           project.field == 'label_pose_termine') ||
    //         (this.selectedVues.id == 12 &&
    //           project.field == 'label_pose_termine') ||
    //         (this.selectedVues.id == 12 &&
    //           (project.field == 'date_bt_planifie' ||
    //             project.field == 'date_ro_planifie' ||
    //             project.field == 'date_rr_planifie' ||
    //             project.field == 'date_iso_interne_planifie_souffle' ||
    //             project.field == 'date_iso_interne_planifie_deroule' ||
    //             project.field == 'date_iso_interne_planifie_plafond' ||
    //             project.field == 'date_iso_interne_planifie_rampant_plus' ||
    //             project.field == 'date_iso_interne_planifie_mur_plus' ||
    //             project.field == 'date_iso_externe_planifie' ||
    //             project.field == 'date_vmc_planifie') &&
    //           !project.entity.date_bt_planifie &&
    //           !project.entity.date_ro_planifie &&
    //           !project.entity.date_rr_planifie &&
    //           !project.entity.date_iso_interne_planifie_souffle &&
    //           !project.entity.date_iso_interne_planifie_deroule &&
    //           !project.entity.date_iso_interne_planifie_plafond &&
    //           !project.entity.date_iso_interne_planifie_rampant_plus &&
    //           !project.entity.date_iso_interne_planifie_mur_plus &&
    //           !project.entity.date_iso_externe_planifie &&
    //           !project.entity.date_vmc_planifie)
    //       ) {
    //         this.fetchProjects({
    //           per_page: 30,
    //           page: this.currentPage,
    //           type_id: this.currentType.id,
    //           keyVue: this.selectedVues.key,
    //           categories: 1,
    //           refresh: true
    //         })
    //       }
    //     }
    //   }
    // },
    // projet
    async handleSelectedProject(checked, fromSelectAll = false) {
      if (this.checkedProjects && this.checkedProjects.length == 1 && checked) {
        this.getProjectsExtraProject.checked = true
      } else {
        this.getProjectsExtraProject.checked = false
      }
      if (fromSelectAll) {
        if (this.selectAll) {
          this.getProjectsProjects.forEach(item => {
            item.checked = this.selectAll
          })
        } else {
          this.checkedProjects.forEach(item => {
            item.checked = this.selectAll
          })
        }

        if (this.getProjectsExtraProject) {
          this.getProjectsExtraProject.checked = false
        }
      }
    },
    handleCloseMultiProject() {
      this.selectAll = false
      this.handleSelectedProject(false, true)
    },
    siwtchType(type) {
      this.sortDirection = 'asc'
      this.currentSort = 'creation_date'
      this.currentType = type
      localStorage.setItem('typeProject', this.currentType.id)
      this.proccessingPipelineFilter = true
      this.setPipeLineFilters()
      this.proccessingPipelineFilter = false
      this.currentPage = 1
      this.currentPipelineIndex = 0
      this.hiddenColonneCumacType = true
      // if (this.currentType && this.currentType.id == 112) {
      //   this.selectedVues = {
      //     id: 0,
      //     label: ' Tout le tableau, toutes les colonnes'
      //   }
      // }
      // this.fetchProjectFields({ id_dtype: this.currentType.id })
      if (this.selectedFilterId !== null) {
        this.notSave = false
        this.applySort(this.selectedFilterId)
      } else {
        if (localStorage.getItem('filter')) {
          this.formatedCustomFilters = JSON.parse(
            localStorage.getItem('filter')
          )
        }
        this.updateFilter()
        this.applySort()
      }
    },
    closeModal() {
      this.$router.push('/projects')
      if (this.checkedProjects && this.checkedProjects.length == 1) {
        this.checkedProjects[0].checked = false
      }
    },
    createNewProject() {
      // if (this.currentType && this.currentType.id == 112) {
      //   this.createProjectTh({ currentType: this.currentType })
      // } else {
      //   this.newProjectState = true
      // }
      this.newProjectState = true
    },
    siwtchFilterPipeline(filter, index) {
      this.currentFilterPipeline = filter
      this.updateFilter()
      this.currentPage = 1
      this.currentPipelineIndex = index
      this.applySort()
    },
    async handleCreateProject(project) {
      if (project && project.type_id === this.currentType.id) {
        project.checked = true
        await this.fetchSingleProject({
          id: this.checkedProjects[0].id,
          checked: true
        })
      } else {
        this.$router.push('/projects/' + project.id)
      }
      this.updateCounters()
      this.newProjectState = false
    },
    async updateCounters() {
      this.updateProjetsTypesCounter()
      this.updatePipelinesCounter()
    },
    setPipeLineFilters(pipelineIndex = 0) {
      if (this.currentType) {
        for (
          let index = 0;
          index < this.getPipelinesPipelines.length;
          index++
        ) {
          const pipe = this.getPipelinesPipelines[index]
          if (
            pipe.type &&
            pipe.type.id == this.currentType.id &&
            pipe.is_principal == 1
          ) {
            // if (this.currentType.name.toLowerCase() == 'iso') {
            //   let filters =
            //     pipe.stages.length < 5 ? pipe.stages : pipe.stages.slice(0, 5)
            //   if (
            //     this.currentUser.type == 'user.agent' ||
            //     this.currentUser.type == 'user.commercial' ||
            //     this.currentUser.type == 'user.commercial.ite'
            //   ) {
            //     filters = pipe.stages.filter(s => {
            //       return [352, 353, 357, 360, 362].includes(s.id)
            //     })
            //   }
            //   this.pipelineFilters = [{ id: null, name: 'TOUS' }, ...filters]
            // } else {
            // const filters =
            //   pipe.stages.length < 5 ? pipe.stages : pipe.stages.slice(0, 5)
            this.pipelineFilters = [{ id: null, name: 'TOUS' }, ...pipe.stages]
            // }
            this.currentFilterPipeline = this.pipelineFilters[pipelineIndex]

            return
          }
        }
        this.pipelineFilters = []
      }
    },
    updateFilter(InitFilterId = false) {
      this.notSave = true
      if (InitFilterId == true) {
        this.selectedFilterId = null
      }

      // FILTERS DES STADE
      if (
        this.currentFilterPipeline &&
        this.currentFilterPipeline.id !== null &&
        (!this.formatedCustomFilters || !this.formatedCustomFilters.length)
      ) {
        const pipelineFilter = [
          {
            glue: 'and',
            conditions: [
              {
                object: 'project',
                field_key: 'stage',
                operator: '=',
                value: this.currentFilterPipeline.id
              }
            ]
          }
        ]
        this.conditions = {
          glue: 'and',
          conditions: pipelineFilter
        }
      } else {
        // FILTERS CUSTOM FIELD
        let conditionAnd = []
        let conditinOr = []
        let conditions = []
        for (
          let index = 0;
          index < this.formatedCustomFilters.length;
          index++
        ) {
          const formatedfilter = this.formatedCustomFilters[index]
          if (formatedfilter.glue == 'and') {
            if (formatedfilter.value && formatedfilter.value.length) {
              if (
                formatedfilter.key != 'contact_full_name' &&
                formatedfilter.key != 'num_maison' &&
                formatedfilter.key != 'campaign' &&
                formatedfilter.key != 'creation_date' &&
                formatedfilter.key != 'modification_date' &&
                formatedfilter.key != 'closing_date' &&
                formatedfilter.key != 'date_debut_formation' &&
                formatedfilter.filter != 'dossier' &&
                formatedfilter.key != 'travaux_date' &&
                formatedfilter.key != 'travaux_status' &&
                formatedfilter.key != 'previsite_status' &&
                formatedfilter.key != 'previsite_date' &&
                formatedfilter.key != 'cumac' &&
                formatedfilter.key != 'zone' &&
                formatedfilter.key != 'precarity' &&
                formatedfilter.key != 'heating_type'
              ) {
                formatedfilter.value.forEach(value => {
                  conditionAnd.push({
                    object: 'project',
                    field_key: formatedfilter.key,
                    operator: formatedfilter.operateur,
                    value: value
                  })
                })
              } else if (
                (formatedfilter.key == 'creation_date' ||
                  formatedfilter.key == 'modification_date' ||
                  formatedfilter.key == 'closing_date' ||
                  formatedfilter.key == 'travaux_date' ||
                  formatedfilter.key == 'previsite_date' ||
                  formatedfilter.key == 'cumac' ||
                  formatedfilter.key == 'date_debut_formation') &&
                formatedfilter.operateur == 8
              ) {
                formatedfilter.value.forEach(value => {
                  conditionAnd.push({
                    object: 'project',
                    field_key: formatedfilter.key,
                    operator: formatedfilter.operateur,
                    value:
                      formatedfilter.key == 'cumac' ? parseFloat(value) : value
                  })
                })
              } else {
                conditionAnd.push({
                  object: 'project',
                  field_key: formatedfilter.key,
                  operator: formatedfilter.operateur,
                  value:
                    formatedfilter.key == 'cumac'
                      ? parseFloat(formatedfilter.value)
                      : formatedfilter.value
                })
              }
            }
          } else {
            if (formatedfilter.value && formatedfilter.value.length) {
              if (formatedfilter.key == 'campaign') {
                conditinOr.push({
                  object: 'project',
                  field_key: formatedfilter.key,
                  operator: formatedfilter.operateur,
                  value: formatedfilter.value
                })
              } else {
                formatedfilter.value.forEach(value => {
                  conditinOr.push({
                    object: 'project',
                    field_key: formatedfilter.key,
                    operator: formatedfilter.operateur,
                    value: value
                  })
                })
              }
            }
          }
        }
        if (conditionAnd && conditionAnd.length) {
          let operator = 0
          for (let index = 0; index < conditionAnd.length; index++) {
            const object = conditionAnd[index]
            if (object.operator == 8) {
              if (operator % 2 == 0) {
                conditionAnd[index] = {
                  object: 'project',
                  field_key: object.field_key,
                  operator: '>=',
                  value: object.value
                }
                operator = operator + 1
              } else {
                conditionAnd[index] = {
                  object: 'project',
                  field_key: object.field_key,
                  operator: '<=',
                  value: object.value
                }
                operator = operator + 1
              }
            }
          }
          conditions = [
            {
              glue: 'and',
              conditions: conditionAnd
            }
          ]
        }
        if (conditinOr && conditinOr.length) {
          conditions = [
            {
              glue: 'or',
              conditions: conditinOr
            }
          ]
        }
        if (
          conditionAnd &&
          conditionAnd.length &&
          conditinOr &&
          conditinOr.length
        ) {
          conditions = [
            {
              glue: ' and',
              conditions: conditionAnd
            },
            {
              glue: 'or',
              conditions: conditinOr
            }
          ]
        }
        this.conditions = {
          glue: 'and',
          conditions: conditions
        }
      }
    },
    updateCustomFilters() {
      this.formatedCustomFilters = []
      for (const property in this.customFilters) {
        if (
          this.customFilters[property] &&
          this.customFilters[property].value
        ) {
          this.formatedCustomFilters.push(this.customFilters[property])
        }
      }
      localStorage.setItem('filter', JSON.stringify(this.formatedCustomFilters))
      localStorage.removeItem('filterCreer')
      localStorage.removeItem('filterPrincipal')
      localStorage.removeItem('filterPrincipalId')
    },
    updateDossier(value, field, entity) {
      this.updateProject({
        entity: entity,
        field: field,
        value: value ? 1 : 0,
        checked: false
      })
    },
    handlChangeCustomFilter(payload) {
      payload.forEach(f => {
        this.customFilters[f.key] = f
      })
      this.updateCustomFilters()
      this.updateFilter(true)
      this.currentPage = 1
      this.applySort(this.selectedFilterId)
    },
    deleteCustomFilter(index, item) {
      if (item.valueLabel) {
        delete this.customFilters[this.formatedCustomFilters[index].key]
      }
      this.formatedCustomFiltersTH = []
      this.updateCustomFilters()
      this.updateFilter()
      this.currentPage = 1
      this.applySort()
      this.notSave = true
      this.filterPrincipal = false
    },
    resetCustomFilter() {
      this.customFilters = []
      this.formatedCustomFilters = []
      this.formatedCustomFiltersTH = []
      this.updateCustomFilters()
      this.updateFilter()
      this.currentPage = 1
      this.applySort()
      this.notSave = true
      this.filterPrincipal = false
    },
    async handlePaginationChange(page) {
      if (
        !this.filterPrincipal &&
        ((this.formatedCustomFilters && this.formatedCustomFilters.length) ||
          (this.currentFilterPipeline &&
            this.currentFilterPipeline.id !== null))
      ) {
        if (
          this.currentSort == 'creation_date' ||
          this.currentSort == 'modification_date' ||
          this.currentSort == 'closing_date' ||
          this.currentSort == 'date_debut_formation'
        ) {
          if (this.sortDirection === 'asc') {
            this.fetchProjects({
              per_page: 50,
              page: page,
              pagination: true,
              sort_by_desc: this.currentSort,
              conditions: this.conditions,
              type_id: this.currentType.id,
              has_filiales_isolations:
                !this.hiddenColonneTypeIsolation == false ? 0 : 1,
              has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1
            })
          } else {
            this.fetchProjects({
              per_page: 50,
              page: page,
              pagination: true,
              sort_by: this.currentSort,
              conditions: this.conditions,
              type_id: this.currentType.id,
              has_filiales_isolations:
                !this.hiddenColonneTypeIsolation == false ? 0 : 1,
              has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1
            })
          }
        } else {
          if (this.sortDirection === 'asc') {
            this.fetchProjects({
              per_page: 50,
              page: page,
              pagination: true,
              sort_by: this.currentSort,
              conditions: this.conditions,
              type_id: this.currentType.id,
              has_filiales_isolations:
                !this.hiddenColonneTypeIsolation == false ? 0 : 1,
              has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1
            })
          } else {
            this.fetchProjects({
              per_page: 50,
              page: page,
              pagination: true,
              sort_by_desc: this.currentSort,
              conditions: this.conditions,
              type_id: this.currentType.id,
              has_filiales_isolations:
                !this.hiddenColonneTypeIsolation == false ? 0 : 1,
              has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1
            })
          }
        }
      } else if (
        this.filterPrincipal &&
        localStorage.getItem('filterPrincipalId') &&
        localStorage.getItem('filterPrincipal') &&
        JSON.parse(localStorage.getItem('filterPrincipalId')) &&
        JSON.parse(localStorage.getItem('filterPrincipalId')).id
      ) {
        if (
          this.currentSort == 'creation_date' ||
          this.currentSort == 'modification_date' ||
          this.currentSort == 'closing_date' ||
          this.currentSort == 'date_debut_formation'
        ) {
          if (this.sortDirection === 'asc') {
            this.fetchProjects({
              per_page: 50,
              page: page,
              pagination: true,
              sort_by_desc: this.currentSort,
              filter_id: JSON.parse(localStorage.getItem('filterPrincipalId'))
                .id,
              type_id: this.currentType.id,
              has_filiales_isolations:
                !this.hiddenColonneTypeIsolation == false ? 0 : 1,
              has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1
            })
          } else {
            this.fetchProjects({
              per_page: 50,
              page: page,
              pagination: true,
              sort_by: this.currentSort,
              filter_id: JSON.parse(localStorage.getItem('filterPrincipalId'))
                .id,
              type_id: this.currentType.id,
              has_filiales_isolations:
                !this.hiddenColonneTypeIsolation == false ? 0 : 1,
              has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1
            })
          }
        } else {
          if (this.sortDirection === 'asc') {
            this.fetchProjects({
              per_page: 50,
              page: page,
              pagination: true,
              sort_by: this.currentSort,
              filter_id: JSON.parse(localStorage.getItem('filterPrincipalId'))
                .id,
              type_id: this.currentType.id,
              has_filiales_isolations:
                !this.hiddenColonneTypeIsolation == false ? 0 : 1,
              has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1
            })
          } else {
            this.fetchProjects({
              per_page: 50,
              page: page,
              pagination: true,
              sort_by_desc: this.currentSort,
              filter_id: JSON.parse(localStorage.getItem('filterPrincipalId'))
                .id,
              type_id: this.currentType.id,
              has_filiales_isolations:
                !this.hiddenColonneTypeIsolation == false ? 0 : 1,
              has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1
            })
          }
        }
      } else {
        if (
          this.currentSort == 'creation_date' ||
          this.currentSort == 'modification_date' ||
          this.currentSort == 'closing_date' ||
          this.currentSort == 'date_debut_formation'
        ) {
          if (this.sortDirection === 'asc') {
            this.fetchProjects({
              per_page: 50,
              page: page,
              pagination: true,
              sort_by_desc: this.currentSort,
              type_id: this.currentType.id,
              has_filiales_isolations:
                !this.hiddenColonneTypeIsolation == false ? 0 : 1,
              has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1
            })
          } else {
            this.fetchProjects({
              per_page: 50,
              page: page,
              pagination: true,
              sort_by: this.currentSort,
              type_id: this.currentType.id,
              has_filiales_isolations:
                !this.hiddenColonneTypeIsolation == false ? 0 : 1,
              has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1
            })
          }
        } else {
          if (this.sortDirection === 'asc') {
            this.fetchProjects({
              per_page: 50,
              page: page,
              pagination: true,
              sort_by: this.currentSort,
              type_id: this.currentType.id,
              has_filiales_isolations:
                !this.hiddenColonneTypeIsolation == false ? 0 : 1,
              has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1
            })
          } else {
            this.fetchProjects({
              per_page: 50,
              page: page,
              pagination: true,
              sort_by_desc: this.currentSort,
              type_id: this.currentType.id,
              has_filiales_isolations:
                !this.hiddenColonneTypeIsolation == false ? 0 : 1,
              has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1
            })
          }
        }
      }
    },
    // handlePerPageChange(perpage) {
    //   if (perpage != '') {
    //     this.TousLingeTH = 0
    //     this.fetchProjects({
    //       page: this.currentPage,
    //       per_page: perpage,
    //       type_id: this.currentType.id,
    //       categories: 1,
    //       refresh: false
    //     })
    //   }
    // },
    // handleTousLigneTableTH(e) {
    //   this.perPage = ''
    //   this.currentPage = 1
    //   if (e == 1) {
    //     this.fetchProjects({
    //       page: this.currentPage,
    //       display_all: e,
    //       type_id: this.currentType.id,
    //       categories: 1,
    //       refresh: false
    //     })
    //   } else {
    //     this.perPage = 30
    //     this.fetchProjects({
    //       page: this.currentPage,
    //       per_page: 30,
    //       type_id: this.currentType.id,
    //       categories: 1,
    //       refresh: false
    //     })
    //   }
    // },
    // handlePaginationChangeTH(page) {
    //   this.fetchProjects({
    //     per_page: this.perPage,
    //     page: page,
    //     pagination: true,
    //     type_id: this.currentType.id,
    //     categories: 1,
    //     refresh: false
    //   })
    // },
    async refreshPipeline() {
      this.updatePipelinesCounter()
      // this.fetchProjects({
      //   filters: this.filters,
      //   page: this.currentPage,
      //   pagination: true,
      // })
    },
    // todo : for counters auto refresh
    setSynchronizers(synchronizer, timer = 5) {
      this.synchronizers.push(synchronizer)
      setInterval(() => {
        synchronizer
      }, timer)
    },
    clearSynchronizers() {
      for (const synchronizer of this.synchronizers) {
        if (synchronizer) {
          clearInterval(synchronizer)
        }
      }
    },
    sort(fieldName) {
      if (this.currentSort === fieldName) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'
      } else {
        this.sortDirection = 'asc'
      }
      this.currentSort = fieldName
      this.applySort(this.selectedFilterId)
    },
    async applySort(filter_id) {
      if (
        (!filter_id || (filter_id && filter_id == null)) &&
        ((this.formatedCustomFilters && this.formatedCustomFilters.length) ||
          (this.currentFilterPipeline &&
            this.currentFilterPipeline.id !== null))
      ) {
        if (
          this.currentSort == 'creation_date' ||
          this.currentSort == 'modification_date' ||
          this.currentSort == 'closing_date' ||
          this.currentSort == 'date_debut_formation'
        ) {
          if (this.sortDirection === 'asc') {
            this.fetchProjects({
              per_page: 50,
              conditions: this.conditions,
              page: this.currentPage,
              sort_by_desc: this.currentSort,
              type_id: this.currentType.id,
              has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1,
              has_filiales_isolations:
                !this.hiddenColonneTypeIsolation == false ? 0 : 1
            })
          } else {
            this.fetchProjects({
              per_page: 50,
              conditions: this.conditions,
              page: this.currentPage,
              sort_by: this.currentSort,
              type_id: this.currentType.id,
              has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1,
              has_filiales_isolations:
                !this.hiddenColonneTypeIsolation == false ? 0 : 1
            })
          }
        } else {
          if (this.sortDirection === 'asc') {
            this.fetchProjects({
              per_page: 50,
              conditions: this.conditions,
              page: this.currentPage,
              sort_by: this.currentSort,
              type_id: this.currentType.id,
              has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1,
              has_filiales_isolations:
                !this.hiddenColonneTypeIsolation == false ? 0 : 1
            })
          } else {
            this.fetchProjects({
              per_page: 50,
              conditions: this.conditions,
              page: this.currentPage,
              sort_by_desc: this.currentSort,
              type_id: this.currentType.id,
              has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1,
              has_filiales_isolations:
                !this.hiddenColonneTypeIsolation == false ? 0 : 1
            })
          }
        }
      } else if (
        filter_id &&
        filter_id !== null &&
        this.formatedCustomFilters &&
        this.formatedCustomFilters.length
      ) {
        if (
          this.currentSort == 'creation_date' ||
          this.currentSort == 'modification_date' ||
          this.currentSort == 'closing_date' ||
          this.currentSort == 'date_debut_formation'
        ) {
          if (this.sortDirection === 'asc') {
            this.fetchProjects({
              per_page: 50,
              page: this.currentPage,
              sort_by_desc: this.currentSort,
              type_id: this.currentType.id,
              filter_id: filter_id,
              has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1,
              has_filiales_isolations:
                !this.hiddenColonneTypeIsolation == false ? 0 : 1
            })
          } else {
            this.fetchProjects({
              per_page: 50,
              page: this.currentPage,
              sort_by: this.currentSort,
              type_id: this.currentType.id,
              filter_id: filter_id,
              has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1,
              has_filiales_isolations:
                !this.hiddenColonneTypeIsolation == false ? 0 : 1
            })
          }
        } else {
          if (this.sortDirection === 'asc') {
            this.fetchProjects({
              per_page: 50,
              page: this.currentPage,
              sort_by: this.currentSort,
              type_id: this.currentType.id,
              filter_id: filter_id,
              has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1,
              has_filiales_isolations:
                !this.hiddenColonneTypeIsolation == false ? 0 : 1
            })
          } else {
            this.fetchProjects({
              per_page: 50,
              page: this.currentPage,
              sort_by_desc: this.currentSort,
              type_id: this.currentType.id,
              filter_id: filter_id,
              has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1,
              has_filiales_isolations:
                !this.hiddenColonneTypeIsolation == false ? 0 : 1
            })
          }
        }
      } else if (
        this.filterPrincipal &&
        localStorage.getItem('filterPrincipalId') &&
        localStorage.getItem('filterPrincipal') &&
        JSON.parse(localStorage.getItem('filterPrincipalId')) &&
        JSON.parse(localStorage.getItem('filterPrincipalId')).id
      ) {
        let filter_id =
          JSON.parse(localStorage.getItem('filterPrincipalId')) &&
          JSON.parse(localStorage.getItem('filterPrincipalId')).id
            ? JSON.parse(localStorage.getItem('filterPrincipalId')).id
            : localStorage.getItem('filterPrincipalId')
        if (
          this.currentSort == 'creation_date' ||
          this.currentSort == 'modification_date' ||
          this.currentSort == 'closing_date' ||
          this.currentSort == 'date_debut_formation'
        ) {
          if (this.sortDirection === 'asc') {
            this.fetchProjects({
              filter_id: filter_id,
              per_page: 50,
              page: this.currentPage,
              sort_by_desc: this.currentSort,
              type_id: this.currentType.id,
              has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1,
              has_filiales_isolations:
                !this.hiddenColonneTypeIsolation == false ? 0 : 1
            })
          } else {
            this.fetchProjects({
              filter_id: filter_id,
              per_page: 50,
              page: this.currentPage,
              sort_by: this.currentSort,
              type_id: this.currentType.id,
              has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1,
              has_filiales_isolations:
                !this.hiddenColonneTypeIsolation == false ? 0 : 1
            })
          }
        } else {
          if (this.sortDirection === 'asc') {
            this.fetchProjects({
              filter_id: filter_id,
              per_page: 50,
              page: this.currentPage,
              sort_by: this.currentSort,
              type_id: this.currentType.id,
              has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1,
              has_filiales_isolations:
                !this.hiddenColonneTypeIsolation == false ? 0 : 1
            })
          } else {
            this.fetchProjects({
              filter_id: filter_id,
              per_page: 50,
              page: this.currentPage,
              sort_by_desc: this.currentSort,
              type_id: this.currentType.id,
              has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1,
              has_filiales_isolations:
                !this.hiddenColonneTypeIsolation == false ? 0 : 1
            })
          }
        }
      } else {
        if (
          this.currentSort == 'creation_date' ||
          this.currentSort == 'modification_date' ||
          this.currentSort == 'closing_date' ||
          this.currentSort == 'date_debut_formation'
        ) {
          if (this.sortDirection === 'asc') {
            this.fetchProjects({
              per_page: 50,
              page: this.currentPage,
              sort_by_desc: this.currentSort,
              type_id: this.currentType.id,
              has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1,
              has_filiales_isolations:
                !this.hiddenColonneTypeIsolation == false ? 0 : 1,
              has_cumac_type_renovation:
                !this.hiddenColonneCumacType == false ? 0 : 1
            })
          } else {
            this.fetchProjects({
              per_page: 50,
              page: this.currentPage,
              sort_by: this.currentSort,
              type_id: this.currentType.id,
              has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1,
              has_filiales_isolations:
                !this.hiddenColonneTypeIsolation == false ? 0 : 1,
              has_cumac_type_renovation:
                !this.hiddenColonneCumacType == false ? 0 : 1
            })
          }
        } else {
          if (this.sortDirection === 'asc') {
            this.fetchProjects({
              per_page: 50,
              page: this.currentPage,
              sort_by: this.currentSort,
              type_id: this.currentType.id,
              has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1,
              has_filiales_isolations:
                !this.hiddenColonneTypeIsolation == false ? 0 : 1,
              has_cumac_type_renovation:
                !this.hiddenColonneCumacType == false ? 0 : 1
            })
          } else {
            this.fetchProjects({
              per_page: 50,
              page: this.currentPage,
              sort_by_desc: this.currentSort,
              type_id: this.currentType.id,
              has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1,
              has_filiales_isolations:
                !this.hiddenColonneTypeIsolation == false ? 0 : 1,
              has_cumac_type_renovation:
                !this.hiddenColonneCumacType == false ? 0 : 1
            })
          }
        }
      }
      // if (this.currentType && this.currentType.id == 112) {
      //   this.fetchProjects({
      //     per_page: 30,
      //     page: this.currentPage,
      //     type_id: this.currentType.id,
      //     categories: 1,
      //     refresh: false
      //   })
      // }
    },
    exportExcel() {
      let ObjectExport = {}
      if (this.selectedFilterId !== null) {
        if (
          this.currentSort == 'creation_date' ||
          this.currentSort == 'modification_date' ||
          this.currentSort == 'closing_date' ||
          this.currentSort == 'date_debut_formation'
        ) {
          if (this.sortDirection === 'asc') {
            ObjectExport = {
              filter_id: this.selectedFilterId,
              sort_by_desc: this.currentSort
            }
          } else {
            ObjectExport = {
              filter_id: this.selectedFilterId,
              sort_by: this.currentSort
            }
          }
        } else {
          if (this.sortDirection === 'asc') {
            ObjectExport = {
              filter_id: this.selectedFilterId,
              sort_by: this.currentSort
            }
          } else {
            ObjectExport = {
              filter_id: this.selectedFilterId,
              sort_by_desc: this.currentSort
            }
          }
        }
      } else {
        if (
          this.currentSort == 'creation_date' ||
          this.currentSort == 'modification_date' ||
          this.currentSort == 'closing_date' ||
          this.currentSort == 'date_debut_formation'
        ) {
          if (this.sortDirection === 'asc') {
            ObjectExport = {
              sort_by_desc: this.currentSort
            }
          } else {
            ObjectExport = {
              sort_by: this.currentSort
            }
          }
        } else {
          if (this.sortDirection === 'asc') {
            ObjectExport = {
              sort_by: this.currentSort
            }
          } else {
            ObjectExport = {
              sort_by_desc: this.currentSort
            }
          }
        }
        if (this.conditions.conditions.length > 0) {
          ObjectExport.conditions = this.conditions
        }
      }
      ObjectExport.type_id = this.currentType.id
      this.exportProjectFile(ObjectExport)
    },
    importExcel() {
      this.$refs.fileInput.value = null
      this.$refs.fileInput.click()
    },
    async handleFileUpload(e) {
      let files = e.target.files[0]
      const response = await this.uploadFileExcel({
        files: files,
        type_id: this.currentType.id
      })
      if (response) {
        this.applySort(this.selectedFilterId)
        this.messageUplaodExcel = true
        setTimeout(() => {
          this.messageUplaodExcel = false
        }, 10000)
      }
    },
    copyMessageError(text) {
      const el = document.createElement('textarea')
      el.value = text
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      el.setSelectionRange(0, 99999) /*For mobile devices*/
      document.execCommand('copy')
      document.body.removeChild(el)
    },
    // SAVE FILTERS
    hideModal(ref) {
      this.$refs[ref].hide()
      this.selectedSaveFilters = null
      this.nameFilters = null
      this.conditionsAnd = []
      this.conditionsOr = []
      if (ref == 'selected_Filter_th') {
        this.resetModalSelectedFilterTH()
      } else if (ref == 'add_Filter_th') {
        this.resetModalAddFilterTH()
      } else if (ref == 'deleted_Filter_th') {
        this.resetModalDeleteFilterTH()
      }
    },
    ModalSaveFilters() {
      this.$refs['modal-save-filters'].show()
    },
    async savefilters(name) {
      if (name) {
        const payload = {
          name: name,
          is_for_visibility: 0,
          type: 'project',
          conditions: this.conditions
        }
        const response = await this.ajouterFilterTH(payload)
        if (response) {
          this.hideModal('modal-save-filters')
          this.notSave = false
          this.selectedFilterId = null
          if (this.getFiltersProjectTH && this.getFiltersProjectTH.length) {
            await this.selectedFilterTHCreer(this.getFiltersProjectTH[0])
            this.filterIdSelected = this.getFiltersProjectTH[0]
          }
          this.$refs['selected_Filter_th'].show()
          // let formatedFilter = this.formatedCustomFilters.map(f => {
          //   return {
          //     glue: f.glue,
          //     key: f.key,
          //     keyLabel: name,
          //     operateur: f.operateur,
          //     value: f.value
          //   }
          // })

          // this.formatedCustomFilters = [
          //   formatedFilter.find(e => {
          //     return e.keyLabel == name
          //   })
          // ]

          this.$nextTick(async () => {
            this.formatedCustomFilters = []
            this.filterPrincipal = true
            localStorage.removeItem('filter')
            localStorage.removeItem('filterCreer')
            // await this.fetchSaveFilters()
            // if (this.getSaveFilters && this.getSaveFilters.length) {
            //   let filterSaveId = this.getSaveFilters.find(f => f.name == name)
            //   if (filterSaveId && filterSaveId.id) {
            //     localStorage.setItem('filterCreer', filterSaveId.id)
            //     this.selectedFilterId = localStorage.getItem('filterCreer')
            // localStorage.removeItem('filter')
            //     localStorage.removeItem('filterPrincipal')
            //     localStorage.removeItem('filterPrincipalId')

            //   }
            // }
            localStorage.setItem(
              'filterPrincipal',
              JSON.stringify(this.conditionsTH)
            )
            setTimeout(() => {
              this.formatedCustomFiltersTH = this.conditionsTH
            })
          })
        }
      } else {
        this.hideModal('modal-save-filters')
      }
    },
    // SELECTED FILTER
    async ModalSelectFilters() {
      this.$refs['modal-select-filters'].show()
    },
    changeSelectFilter(filter) {
      this.selectFiler = true
      this.loading = true
      this.conditionsOr = []
      this.conditionsAnd = []
      for (
        let index = 0;
        index < filter.conditions.conditions.length;
        index++
      ) {
        const conditions = filter.conditions.conditions[index]
        if (conditions.glue == 'and') {
          conditions.conditions.forEach(async value => {
            let test = null
            let valuesubstage = null
            let name = null
            switch (value.field_key) {
              case 'creation_date':
                name = 'Créé le'
                if (value.operator == '=') {
                  test = 'Égale à ' + value.value
                } else if (value.operator == '<>') {
                  test = 'Pas Égale à ' + value.value
                } else if (value.operator == '>') {
                  test = 'Supérieur à ' + value.value
                } else if (value.operator == '<') {
                  test = 'Inférieur à ' + value.value
                } else if (value.operator == '>=') {
                  test = 'Supérieur ou égale à ' + value.value
                } else if (value.operator == '<=') {
                  test = 'Inférieur ou égale à ' + value.value
                } else if (value.operator == '<=') {
                  test = 'Inférieur ou égale à ' + value.value
                } else if (
                  value.operator[0] == '>=' &&
                  value.operator[1] == '<='
                ) {
                  test = 'Valeur comprise  entre' + value
                }
                break
              case 'modification_date':
                name = 'Dernière modification'
                if (value.operator == '=') {
                  test = 'Égale à ' + value.value
                } else if (value.operator == '<>') {
                  test = 'Pas Égale à ' + value.value
                } else if (value.operator == '>') {
                  test = 'Supérieur à ' + value.value
                } else if (value.operator == '<') {
                  test = 'Inférieur à ' + value.value
                } else if (value.operator == '>=') {
                  test = 'Supérieur ou égale à ' + value.value
                } else if (value.operator == '<=') {
                  test = 'Inférieur ou égale à ' + value.value
                } else if (value.operator == '<=') {
                  test = 'Inférieur ou égale à ' + value.value
                } else if (
                  value.operator[0] == '>=' &&
                  value.operator[1] == '<='
                ) {
                  test = 'Valeur comprise  entre' + value
                }
                break
              case 'contact_full_name':
                name = 'Contact'
                test = value.value
                break
              case 'num_maison':
                name = 'Numéro Maison'
                test = value.value
                break
              case 'campaign':
                name = 'Campagne'
                test = value.value
                break
              case 'cumac':
                name = 'Cumac'
                test = value.value
                if (value.operator == '=') {
                  test = 'Égale à ' + value.value
                } else if (value.operator == '<>') {
                  test = 'Pas Égale à ' + value.value
                } else if (value.operator == '>') {
                  test = 'Supérieur à ' + value.value
                } else if (value.operator == '<') {
                  test = 'Inférieur à ' + value.value
                } else if (value.operator == '>=') {
                  test = 'Supérieur ou égale à ' + value.value
                } else if (value.operator == '<=') {
                  test = 'Inférieur ou égale à ' + value.value
                } else if (value.operator == '<=') {
                  test = 'Inférieur ou égale à ' + value.value
                } else if (
                  value.operator[0] == '>=' &&
                  value.operator[1] == '<='
                ) {
                  test = 'Valeur comprise  entre' + value
                }
                break
              case 'zone':
                name = 'Zone'
                if (value.value != 'vide') {
                  test = value.value
                } else {
                  test = 'vide'
                }
                break
              case 'precarity':
                name = 'Précarité'
                if (value.value != 'vide') {
                  test = value.value
                } else {
                  test = 'vide'
                }
                break
              case 'heating_type':
                name = 'Chauffage'
                if (value.value != 'vide') {
                  test = value.value
                } else {
                  test = 'vide'
                }
                break
              case 'source':
                name = 'Source'
                await this.fetchProjectSource()
                if (value.value != 'vide') {
                  if (this.getProjectsSources) {
                    test = this.getProjectsSources.find(e => {
                      return e.id == value.value
                    }).name
                  }
                } else {
                  test = 'vide'
                }
                break
              case 'comm_regie':
                name = 'Campagne'
                await this.fetchProjectCampagne()
                if (value.value != 'vide') {
                  if (this.getProjectsCampagnes) {
                    test = this.getProjectsCampagnes.find(e => {
                      return e.id == value.value
                    }).full_name
                  }
                } else {
                  test = 'vide'
                }
                break
              case 'status':
                name = 'Statut'
                test = value.value
                break
              case 'stage':
                name = 'Stade'
                test = this.currentStages.find(e => {
                  return e.id == value.value
                }).name
                break
              case 'sub_stage':
                name = 'Sous-stade'
                valuesubstage = this.currentStages.map(stage => {
                  return stage.subStages.map(ss => {
                    return {
                      text: ss.name,
                      value: ss.id
                    }
                  })
                })
                test = valuesubstage
                  .find(e => {
                    return e.find(s => {
                      return s.value == value.value
                    })
                  })
                  .find(s => {
                    return s
                  }).text

                break
              case 'closing_date':
                name = 'Closé le'
                if (value.operator == '=') {
                  test = 'Égale à ' + value.value
                } else if (value.operator == '<>') {
                  test = 'Pas Égale à ' + value.value
                } else if (value.operator == '>') {
                  test = 'Supérieur à ' + value.value
                } else if (value.operator == '<') {
                  test = 'Inférieur à ' + value.value
                } else if (value.operator == '>=') {
                  test = 'Supérieur ou égale à ' + value.value
                } else if (value.operator == '<=') {
                  test = 'Inférieur ou égale à ' + value.value
                } else if (value.operator == '<=') {
                  test = 'Inférieur ou égale à ' + value.value
                } else if (
                  value.operator[0] == '>=' &&
                  value.operator[1] == '<='
                ) {
                  test = 'Valeur comprise  entre' + value
                }
                break
              case 'comm_sed':
                name = 'Sédentaire'
                await this.fetchUsersCommercialSedentaire()
                if (value.value != 'vide') {
                  if (this.getProjectsUsersCommercialsSedentaires) {
                    test = this.getProjectsUsersCommercialsSedentaires.find(
                      e => {
                        return e.id == value.value
                      }
                    ).full_name
                  }
                } else {
                  test = 'vide'
                }
                break
              case 'type_renovation':
                name = 'Type'
                if (value.value != 'vide') {
                  let types = [
                    { id: 'Rénovation globale', name: 'Rénovation globale' },
                    { id: "Ma prime renov'", name: "Ma prime renov'" },
                    { id: 'Autre', name: 'Autre' }
                  ]
                  if (types && types.length) {
                    test = types.find(e => {
                      return e.id == value.value
                    }).name
                  }
                } else {
                  test = 'vide'
                }
                break
              case 'comm_terrain':
                name = 'Commercial'
                await this.fetchUsersCommercialsIte()
                if (value.value != 'vide') {
                  if (this.getProjectsUsersCommercialsIte) {
                    test = this.getProjectsUsersCommercialsIte.find(e => {
                      return e.id == value.value
                    }).full_name
                  }
                } else {
                  test = 'vide'
                }
                break
              case 'depot':
                name = 'Dépôt'
                await this.fetchProjectDepot({
                  exclude: [
                    'accessedByUsers',
                    'commercialsIte',
                    'departments',
                    'editibleByUsers',
                    'poseurs',
                    'team',
                    'visibleByUsers'
                  ]
                })
                if (value.value != 'vide') {
                  if (this.getProjectsDepots) {
                    test = this.getProjectsDepots.find(e => {
                      return e.id == value.value
                    }).name
                  }
                } else {
                  test = 'vide'
                }
                break
              case 'comm_ite':
                name = 'Pré-visiteur'
                await this.fetchUsersCommercialsIte()
                if (value.value != 'vide') {
                  if (this.getProjectsUsersCommercialsIte) {
                    test = this.getProjectsUsersCommercialsIte.find(e => {
                      return e.id == value.value
                    }).full_name
                  }
                } else {
                  test = 'vide'
                }
                break
              case 'poseur':
                name = 'Poseur'
                await this.fetchUsersTechniciens()
                if (value.value != 'vide') {
                  if (this.getProjectsUsersTechniciens) {
                    test = this.getProjectsUsersTechniciens.find(e => {
                      return e.id == value.value
                    }).full_name
                  }
                } else {
                  test = 'vide'
                }
                break
              case 'travaux_status':
                name = 'Statut travaux'
                test = value.value
                break
              case 'travaux_date':
                name = 'Date travaux'
                if (value.operator == '=') {
                  test = 'Égale à ' + value.value
                } else if (value.operator == '<>') {
                  test = 'Pas Égale à ' + value.value
                } else if (value.operator == '>') {
                  test = 'Supérieur à ' + value.value
                } else if (value.operator == '<') {
                  test = 'Inférieur à ' + value.value
                } else if (value.operator == '>=') {
                  test = 'Supérieur ou égale à ' + value.value
                } else if (value.operator == '<=') {
                  test = 'Inférieur ou égale à ' + value.value
                } else if (value.operator == '<=') {
                  test = 'Inférieur ou égale à ' + value.value
                } else if (
                  value.operator[0] == '>=' &&
                  value.operator[1] == '<='
                ) {
                  test = 'Valeur comprise  entre' + value
                }
                break
              case 'previsite_status':
                name = 'Statut rendez-vous le'
                test = value.value
                break
              case 'previsite_date':
                name = 'Rendez-vous le'
                if (value.operator == '=') {
                  test = 'Égale à ' + value.value
                } else if (value.operator == '<>') {
                  test = 'Pas Égale à ' + value.value
                } else if (value.operator == '>') {
                  test = 'Supérieur à ' + value.value
                } else if (value.operator == '<') {
                  test = 'Inférieur à ' + value.value
                } else if (value.operator == '>=') {
                  test = 'Supérieur ou égale à ' + value.value
                } else if (value.operator == '<=') {
                  test = 'Inférieur ou égale à ' + value.value
                } else if (value.operator == '<=') {
                  test = 'Inférieur ou égale à ' + value.value
                } else if (
                  value.operator[0] == '>=' &&
                  value.operator[1] == '<='
                ) {
                  test = 'Valeur comprise  entre' + value
                }
                break
              case 'date_debut_formation':
                name = 'Date de debut'
                if (value.operator == '=') {
                  test = 'Égale à ' + value.value
                } else if (value.operator == '<>') {
                  test = 'Pas Égale à ' + value.value
                } else if (value.operator == '>') {
                  test = 'Supérieur à ' + value.value
                } else if (value.operator == '<') {
                  test = 'Inférieur à ' + value.value
                } else if (value.operator == '>=') {
                  test = 'Supérieur ou égale à ' + value.value
                } else if (value.operator == '<=') {
                  test = 'Inférieur ou égale à ' + value.value
                } else if (value.operator == '<=') {
                  test = 'Inférieur ou égale à ' + value.value
                } else if (
                  value.operator[0] == '>=' &&
                  value.operator[1] == '<='
                ) {
                  test = 'Valeur comprise  entre' + value
                }
                break
              default:
                break
            }
            if (
              this.conditionsAnd.length > 0 &&
              this.conditionsAnd.find(e => {
                return e.field_key == value.field_key
              })
            ) {
              this.conditionsAnd
                .find(e => {
                  return e.field_key == value.field_key
                })
                .list.push(test)
            } else {
              this.conditionsAnd.push({
                field_key: value.field_key,
                label_Key: name,
                list: [test]
              })
            }
            this.$nextTick(() => {
              this.loading = false
            })
          })
        } else {
          conditions.conditions.forEach(async value => {
            let test = null
            let valuesubstage = null
            let name = null
            switch (value.field_key) {
              case 'source':
                name = 'Source'
                await this.fetchProjectSource()
                if (value.value != 'vide') {
                  if (this.getProjectsSources) {
                    test = this.getProjectsSources.find(e => {
                      return e.id == value.value
                    }).name
                  }
                } else {
                  test = 'vide'
                }
                break
              case 'comm_regie':
                name = 'Campagne'
                await this.fetchProjectCampagne()
                if (value.value != 'vide') {
                  if (this.getProjectsCampagnes) {
                    test = this.getProjectsCampagnes.find(e => {
                      return e.id == value.value
                    }).full_name
                  }
                } else {
                  test = 'vide'
                }
                break
              case 'status':
                name = 'Statut'
                test = value.value
                break
              case 'stage':
                name = 'Stade'
                test = this.currentStages.find(e => {
                  return e.id == value.value
                }).name
                break
              case 'sub_stage':
                name = 'Sous-stade'
                valuesubstage = this.currentStages.map(stage => {
                  return stage.subStages.map(ss => {
                    return {
                      text: ss.name,
                      value: ss.id
                    }
                  })
                })
                test = valuesubstage
                  .find(e => {
                    return e.find(s => {
                      return s.value == value.value
                    })
                  })
                  .find(s => {
                    return s
                  }).text

                break
              case 'comm_sed':
                name = 'Sédentaire'
                await this.fetchUsersCommercialSedentaire()
                if (value.value != 'vide') {
                  if (this.getProjectsUsersCommercialsSedentaires) {
                    test = this.getProjectsUsersCommercialsSedentaires.find(
                      e => {
                        return e.id == value.value
                      }
                    ).full_name
                  }
                } else {
                  test = 'vide'
                }
                break
              case 'type_renovation':
                name = 'Type'
                if (value.value != 'vide') {
                  let types = [
                    { id: 'Rénovation globale', name: 'Rénovation globale' },
                    { id: "Ma prime renov'", name: "Ma prime renov'" },
                    { id: 'Autre', name: 'Autre' }
                  ]
                  if (types && types.length) {
                    test = types.find(e => {
                      return e.id == value.value
                    }).name
                  }
                } else {
                  test = 'vide'
                }
                break
              case 'comm_terrain':
                name = 'Commercial'
                await this.fetchUsersCommercialsIte()
                if (value.value != 'vide') {
                  if (this.getProjectsUsersCommercialsIte) {
                    test = this.getProjectsUsersCommercialsIte.find(e => {
                      return e.id == value.value
                    }).full_name
                  }
                } else {
                  test = 'vide'
                }
                break
              case 'depot':
                name = 'Dépôt'
                await this.fetchProjectDepot({
                  exclude: [
                    'accessedByUsers',
                    'commercialsIte',
                    'departments',
                    'editibleByUsers',
                    'poseurs',
                    'team',
                    'visibleByUsers'
                  ]
                })
                if (value.value != 'vide') {
                  if (this.getProjectsDepots) {
                    test = this.getProjectsDepots.find(e => {
                      return e.id == value.value
                    }).name
                  }
                } else {
                  test = 'vide'
                }
                break
              case 'comm_ite':
                name = 'Pré-visiteur'
                await this.fetchUsersCommercialsIte()
                if (value.value != 'vide') {
                  if (this.getProjectsUsersCommercialsIte) {
                    test = this.getProjectsUsersCommercialsIte.find(e => {
                      return e.id == value.value
                    }).full_name
                  }
                } else {
                  test = 'vide'
                }
                break
              case 'poseur':
                name = 'Poseur'
                await this.fetchUsersTechniciens()
                if (value.value != 'vide') {
                  if (this.getProjectsUsersTechniciens) {
                    test = this.getProjectsUsersTechniciens.find(e => {
                      return e.id == value.value
                    }).full_name
                  }
                } else {
                  test = 'vide'
                }
                break
              default:
                break
            }

            if (
              this.conditionsOr.length > 0 &&
              this.conditionsOr.find(e => {
                return e.field_key == value.field_key
              })
            ) {
              this.conditionsOr
                .find(e => {
                  return e.field_key == value.field_key
                })
                .list.push(test)
            } else {
              this.conditionsOr.push({
                field_key: value.field_key,
                label_Key: name,
                list: [test]
              })
            }
            this.$nextTick(() => {
              this.loading = false
            })
          })
        }
      }
    },
    addFilterCreer(object) {
      if (object) {
        this.selectedFilterId = object.id
        localStorage.setItem('filterCreer', this.selectedFilterId)
        localStorage.removeItem('filter')
        localStorage.removeItem('filterPrincipal')
        localStorage.removeItem('filterPrincipalId')
        this.notSave = false
        for (
          let index = 0;
          index < object.conditions.conditions.length;
          index++
        ) {
          const tabCond = object.conditions.conditions[index]
          let formatedFilter = tabCond.conditions.map(f => {
            return {
              glue: tabCond.glue,
              key: f.field_key,
              keyLabel: object.name,
              operateur: f.operator,
              value: f.value
            }
          })
          this.formatedCustomFilters = [
            formatedFilter.find(e => {
              return e.keyLabel == object.name
            })
          ]
        }
        this.hideModal('modal-select-filters')
        this.applySort(object.id)
      } else {
        this.hideModal('modal-select-filters')
      }
    },
    handleDeleteSaveFilters(filter) {
      if (filter) {
        if (
          filter &&
          this.selectedFilterId &&
          filter.id == this.selectedFilterId
        ) {
          const response = this.deleteSaveFilters(filter)
          if (response) {
            this.selectedSaveFilters = null
            this.selectedFilterId = null
            this.conditionsAnd = []
            this.conditionsOr = []
            this.resetCustomFilter()
          }
        } else {
          const response = this.deleteSaveFilters(filter)
          if (response) {
            this.selectedSaveFilters = null
            this.conditionsAnd = []
            this.conditionsOr = []
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'getProjectsProjects',
      'getProjectsExtraProject',
      'getProjectsTypes',
      'getProjectsProcessing',
      'getProjectsError',
      'getContactsError',
      'getPipelinesPipelines',
      'currentUser',
      'getProjectstotalRowsType',
      'getProjectstotalRows',
      'getProjectstotalRowsStade',
      'getProjectstotalRowsTH',
      'getProjectCountProcessing',
      'getSaveFilters',
      'getProjectsSources',
      'getProjectsCampagnes',
      'getProjectsUsersCommercialsSedentaires',
      'getProjectsUsersCommercialsIte',
      'getProjectsDepots',
      'getProjectsUsersTechniciens',
      'getFieldsTHToFiltre',
      'getOperatorTHToFiltre',
      'getFiltersProjectTHLoading',
      'getFiltersProjectTHError',
      'getFiltersProjectTH',
      'getListFiltersProjectTHLoading',
      'getProjectsErrorWarningMultiModal'
    ]),
    computedRef() {
      return function(data) {
        const found = this.ref_tab.find(item => item == data)
        if (found) {
          return true
        } else {
          return false
        }
      }
    },
    // projet th
    visibilityProjectTh: function() {
      if (
        this.currentUser &&
        (this.currentUser.type == 'user.super-admin' ||
          //  USER ADMIN REGIE
          (this.currentUser.type == 'user.admin' &&
            this.currentUser.details &&
            this.currentUser.details.team &&
            this.currentUser.details.team.data &&
            this.currentUser.details.team.data.type == 'regie'))
      ) {
        return true
      } else {
        return false
      }
    },
    // Filters th
    visibilityFilterTh: function() {
      // if (this.currentUser && this.currentUser.type == 'user.super-admin') {
      return true
      // } else {
      //   return false
      // }
    },
    // projet
    currentStages: function() {
      if (this.currentType) {
        for (let i = 0; i < this.getPipelinesPipelines.length; i++) {
          const pipe = this.getPipelinesPipelines[i]
          if (pipe.type && pipe.type.id == this.currentType.id && pipe.stages) {
            return pipe.stages.map(s => {
              return {
                text: s.name,
                value: s.id,
                ...s
              }
            })
          }
        }
      }
      return []
    },
    checkedProjects() {
      if (!this.getProjectsProjects || !this.getProjectsProjects.length) {
        return []
      }
      return this.getProjectsProjects.filter(function(item) {
        return item.checked
      })
    },
    checkedExtreaProject() {
      return (
        this.getProjectsExtraProject && this.getProjectsExtraProject.checked
      )
    },
    parcialSelect() {
      return (
        this.checkedProjects.length > 0 &&
        this.checkedProjects.length < this.getProjectsProjects.length
      )
    },
    readonly: function() {
      if (
        this.currentUser &&
        (this.currentUser.type == 'user.commercial' ||
          this.currentUser.type == 'user.final' ||
          (this.currentUser.type == 'user.admin' &&
            this.currentUser.details &&
            this.currentUser.details.team &&
            this.currentUser.details.team.data &&
            this.currentUser.details.team.data.type == 'regie'))
      ) {
        return true
      }
      return false
    },
    visibilityVoirModalMultiProjet: function() {
      if (
        this.currentUser &&
        (this.currentUser.type == 'user.sub-super-admin' ||
          this.currentUser.type == 'user.super-admin' ||
          this.currentUser.type == 'user.admin')
      ) {
        return true
      }
      return false
    },
    typeISO: function() {
      // if (this.currentType.id !== 110) {
      //   return true
      // }
      // return false
      return true
    },
    typeCPF: function() {
      // if (this.currentType.id == 110) {
      //   return true
      // }
      return false
    },

    typePhotovoltaique: function() {
      if (this.currentType && this.currentType.id == 17) {
        return true
      }
      return false
    },
    visibilityExportExcel: function() {
      if (
        this.currentUser &&
        this.currentUser.details &&
        this.currentUser.details.has_export_excel == 1
      ) {
        return true
      }
      return false
    },
    visibilityImportExcel: function() {
      if (
        this.currentUser &&
        this.currentUser.details &&
        this.currentUser.details.has_import_excel == 1
      ) {
        return true
      }
      return false
    },
    visibilityProjectsTypes: function() {
      // filiale : ATELIER E-LEARNING
      // if (this.currentUser && this.currentUser.team_id == 425) {
      //   return this.getProjectsTypes.filter(type => type.id == 110)
      // } else if (!this.visibilityProjectTh) {
      //   return this.getProjectsTypes.filter(type => type.id != 112)
      // } else {
      //   return this.getProjectsTypes
      // }
      return this.getProjectsTypes
    }
    // computedConditionTh: function() {
    //   return this.conditionsTH
    // }
  },

  watch: {
    checkedProjects(projets) {
      if (this.blockNavigation) {
        return
      }
      if (projets.length === 1 && this.$route.params.id != projets[0].id) {
        const fn = () => {
          this.$router.push('/projects/' + projets[0].id)
          this.transition = false
        }
        if (
          this.getProjectsExtraProject &&
          this.getProjectsExtraProject.checked
        ) {
          this.getProjectsExtraProject.checked = false
          this.transition = true
          setTimeout(fn, 100)
        } else {
          fn()
        }
      } else if (
        this.$route.name != 'projects' &&
        !this.initLoading &&
        (!this.getProjectsExtraProject || !this.getProjectsExtraProject.checked)
      ) {
        this.$router.push('/projects')
      }
    },
    checkedExtreaProject(checked) {
      if (this.blockNavigation) {
        return
      }
      if (
        !checked &&
        this.getProjectsExtraProject &&
        this.getProjectsExtraProject.id == this.$route.params.id &&
        this.checkedProjects.length !== 1
      ) {
        this.$router.push('/projects')
      }
    },
    async $route(route) {
      if (route.name == 'project' && route.params.id) {
        if (
          !this.checkedProjects[0] ||
          this.checkedProjects[0].id != route.params.id
        ) {
          this.blockNavigation = true
          if (this.checkedProjects.length) {
            this.checkedProjects.forEach(item => {
              item.checked = false
            })
          }

          if (this.getProjectsExtraProject) {
            this.getProjectsExtraProject.checked = false
          }

          setTimeout(async () => {
            // check in projects
            let found = false
            for (
              let index = 0;
              index < this.getProjectsProjects.length;
              index++
            ) {
              const project = this.getProjectsProjects[index]
              if (project.id == route.params.id) {
                project.checked = true
                found = true
              }
            }
            if (!found) {
              await this.fetchSingleProject({
                id: route.params.id,
                checked: true
              })
            }
            this.blockNavigation = false
          })
        }
      }
      if (this.$route.name == 'project_activity' && this.$route.params.id) {
        this.fetchSingleProject({
          id: this.$route.params.id,
          checked: true
        })
      }
    },
    getProjectsErrorWarningMultiModal() {
      const h = this.$createElement

      // Pass the VNodes as an array for message and title
      if (
        this.getProjectsErrorWarningMultiModal &&
        this.getProjectsErrorWarningMultiModal.error &&
        this.getProjectsErrorWarningMultiModal.error.messages
      ) {
        // Create the message
        const vNodesMsg = h(
          'div',
          { class: ['d-flex', 'align-items-center'] },
          [
            h('div', { class: ['h1', 'mb-0'] }, [
              h('font-awesome-icon', {
                props: { icon: 'exclamation-circle' }
              })
            ]),
            h('div', { class: ['mb-0', 'ml-2'] }, [
              h(
                'strong',
                this.getProjectsErrorWarningMultiModal &&
                  this.getProjectsErrorWarningMultiModal.error &&
                  Array.isArray(
                    this.getProjectsErrorWarningMultiModal.error.messages
                  )
                  ? this.getProjectsErrorWarningMultiModal.error.messages.map(
                      item => {
                        return h('p', { class: ['mb-0'] }, item)
                      }
                    )
                  : this.getProjectsErrorWarningMultiModal
              )
            ])
          ]
        )
        // Create the title
        const vNodesTitle = h(
          'div',
          {
            class: [
              'd-flex',
              'flex-grow-1',
              'justify-content-between',
              'align-items-baseline',
              'mr-3'
            ]
          },
          [
            h('strong', { class: 'mr-2' }, "Message d'erreur"),
            h(
              'b-button',
              {
                class: 'toast-color-button-copy'
              },
              [
                h('font-awesome-icon', {
                  props: { icon: 'copy' },
                  on: {
                    click: () =>
                      this.copyMessageError(
                        this.getProjectsErrorWarningMultiModal.error.messages
                      )
                  }
                })
              ]
            )
          ]
        )
        this.$bvToast.toast([vNodesMsg], {
          headerClass: 'projet',
          toastClass: 'projet',
          title: [vNodesTitle],
          toaster: 'b-toaster-bottom-right projet',
          autoHideDelay: 100000
        })
      }

      if (
        this.getProjectsErrorWarningMultiModal &&
        this.getProjectsErrorWarningMultiModal.warning &&
        this.getProjectsErrorWarningMultiModal.warning.messages
      ) {
        const vNodesMsgwarning = h(
          'div',
          { class: ['d-flex', 'align-items-center'] },
          [
            h('div', { class: ['h1', 'mb-0'] }, [
              h('font-awesome-icon', {
                props: { icon: 'exclamation-circle' }
              })
            ]),
            h('div', { class: ['mb-0', 'ml-2'] }, [
              h(
                'strong',
                this.getProjectsErrorWarningMultiModal &&
                  this.getProjectsErrorWarningMultiModal.warning &&
                  Array.isArray(
                    this.getProjectsErrorWarningMultiModal.warning.messages
                  )
                  ? this.getProjectsErrorWarningMultiModal.warning.messages.map(
                      item => {
                        return h(
                          'p',
                          { class: ['mb-0'] },
                          item.replace('Avertissement:', '*')
                        )
                      }
                    )
                  : this.getProjectsErrorWarningMultiModal.warning.messages.replace(
                      'Avertissement:',
                      ' '
                    )
              )
            ])
          ]
        )
        const vNodesTitleWarning = h(
          'div',
          {
            class: [
              'd-flex',
              'flex-grow-1',
              'justify-content-between',
              'align-items-baseline',
              'mr-3'
            ]
          },
          [
            h('strong', { class: 'mr-2' }, 'Avertissement'),
            h(
              'b-button',
              {
                class: 'toast-color-button-copy-warning'
              },
              [
                h('font-awesome-icon', {
                  props: { icon: 'copy' },
                  on: {
                    click: () =>
                      this.copyMessageError(
                        this.getProjectsErrorWarningMultiModal.warning.messages
                      )
                  }
                })
              ]
            )
          ]
        )
        this.$bvToast.toast([vNodesMsgwarning], {
          title: [vNodesTitleWarning],
          headerClass: 'warning',
          bodyClass: 'warning',
          toastClass: 'warning',
          variant: 'warning',
          toaster: 'b-toaster-bottom-right projet',
          autoHideDelay: 100000
        })
      }
    },
    getProjectsError() {
      const h = this.$createElement
      // Create the message
      const vNodesMsg = h('div', { class: ['d-flex', 'align-items-center'] }, [
        h('div', { class: ['h1', 'mb-0'] }, [
          h('font-awesome-icon', {
            props: { icon: 'exclamation-circle' }
          })
        ]),
        h('div', { class: ['mb-0', 'ml-2'] }, [
          h(
            'strong',
            Array.isArray(this.getProjectsError)
              ? this.getProjectsError.map(item => {
                  return h('p', { class: ['mb-0'] }, item)
                })
              : this.getProjectsError
          )
        ])
      ])
      // Create the title
      const vNodesTitle = h(
        'div',
        {
          class: [
            'd-flex',
            'flex-grow-1',
            'justify-content-between',
            'align-items-baseline',
            'mr-3'
          ]
        },
        [
          h('strong', { class: 'mr-2' }, "Message d'erreur"),
          h(
            'b-button',
            {
              class: 'toast-color-button-copy'
            },
            [
              h('font-awesome-icon', {
                props: { icon: 'copy' },
                on: {
                  click: () => this.copyMessageError(this.getProjectsError)
                }
              })
            ]
          )
        ]
      )
      // Pass the VNodes as an array for message and title
      if (this.getProjectsError) {
        this.$bvToast.toast([vNodesMsg], {
          headerClass: 'projet',
          toastClass: 'projet',
          title: [vNodesTitle],
          toaster: 'b-toaster-bottom-right projet',
          autoHideDelay: 5000
        })
      }
    },
    getContactsError() {
      const h = this.$createElement
      // Create the message
      const vNodesMsg = h('div', { class: ['d-flex', 'align-items-center'] }, [
        h('div', { class: ['h1', 'mb-0'] }, [
          h('font-awesome-icon', {
            props: { icon: 'exclamation-circle' }
          })
        ]),
        h('div', { class: ['mb-0', 'ml-2'] }, [
          h(
            'strong',
            Array.isArray(this.getContactsError)
              ? this.getContactsError.map(item => {
                  return h('p', { class: ['mb-0'] }, item)
                })
              : this.getContactsError
          )
        ])
      ])
      // Create the title
      const vNodesTitle = h(
        'div',
        {
          class: [
            'd-flex',
            'flex-grow-1',
            'justify-content-between',
            'align-items-baseline',
            'mr-3'
          ]
        },
        [
          h('strong', { class: 'mr-2' }, "Message d'erreur"),
          h(
            'b-button',
            {
              class: 'toast-color-button-copy'
            },
            [
              h('font-awesome-icon', {
                props: { icon: 'copy' },
                on: {
                  click: () => this.copyMessageError(this.getContactsError)
                }
              })
            ]
          )
        ]
      )
      // Pass the VNodes as an array for message and title
      if (this.getContactsError) {
        this.$bvToast.toast([vNodesMsg], {
          headerClass: 'projet',
          toastClass: 'projet',
          title: [vNodesTitle],
          toaster: 'b-toaster-bottom-right projet',
          autoHideDelay: 5000
        })
      }
    }
  },
  filters: {
    formatConditionTh(value, field) {
      for (let index = 0; index < field.length; index++) {
        const formatKey = field[index].key
        switch (value) {
          case value:
            if (value == formatKey) {
              return field[index].name
            }
        }
      }
    },
    FormatOperatorTh(operator) {
      if (operator == '=') {
        return 'est '
      } else if (operator == '<>') {
        return 'n’est pas '
      } else if (operator == 'IS NOT NULL') {
        return 'n’est pas vide '
      } else if (operator == 'IS NULL') {
        return 'est vide '
      } else if (operator == '<') {
        return 'est plus tôt que '
      } else if (operator == '<=') {
        return 'est exactement ou plus tôt que'
      } else if (operator == '>') {
        return 'est plus tard que '
      } else if (operator == '>=') {
        return 'est exactement ou plus tard que '
      } else if (operator == 'LIKE $%') {
        return 'commence par '
      } else if (operator == 'LIKE %$') {
        return 'se termine par '
      } else if (operator == 'NOT LIKE $%') {
        return 'ne commence pas par '
      } else if (operator == 'NOT LIKE %$') {
        return 'ne se termine pas par '
      } else {
        return 'Aucun operator '
      }
    },
    formatKey: function(value) {
      switch (value) {
        // COMMERCIAL SED
        case 'comm_sed':
          return 'commercial_sedentaire'
        // SOURCE
        case 'source':
          return 'source_id'
        case 'comm_regie':
          return 'commercial_regie_id'
        case 'campaign':
          return 'campaign'
        // CUMAC
        case 'cumac':
          return 'c114d08944ce1d709de532c21d4116eec77de3bf043c3c6867'
        // CREER LE
        case 'creation_date':
          return 'created_at'

        // CONTACT
        case 'contact_full_name':
          return 'full_name'
        // STADE
        case 'status':
          return 'status'
        case 'stage':
          return 'cstage_id'
        case 'sub_stage':
          return 'csubstage_id'
        // Assistants maître d'ouvrage
        case 'depot':
          return 'depot_comm_ite_id'
        case 'poseur':
          return 'commercial_terrain_id'
        // Travaux
        case 'travaux_status':
          return 'statut_travaux'
        case 'travaux_date':
          return 'date_previsite'
        default:
          return ''
      }
    }
  },
  async mounted() {
    if (
      this.$route.name == 'projects' &&
      this.$route.params &&
      !this.$route.params.id
    ) {
      this.getProjectsExtraProject.checked = false
      if (this.checkedProjects && this.checkedProjects.length == 1) {
        this.checkedProjects[0].checked = false
      }
    }

    this.initLoading = true
    await this.fetchProjetsTypes({
      exclude: ['fields']
    })
    if (localStorage.getItem('typeProject')) {
      for (
        let index = 0;
        index < this.visibilityProjectsTypes.length;
        index++
      ) {
        const type = this.visibilityProjectsTypes[index]
        if (type.id == localStorage.getItem('typeProject')) {
          this.currentType = type
        } else {
          this.currentType = this.visibilityProjectsTypes[0]
        }
      }
    } else {
      this.currentType = this.visibilityProjectsTypes[0]
      localStorage.setItem('typeProject', this.currentType.id)
    }
    // await this.fetchProjectFields({ id_dtype: this.currentType.id })
    // await this.fetchContactFields()
    if (
      this.$route.name == 'project' ||
      (this.$route.name == 'project_activity' && this.$route.params.id)
    ) {
      await this.fetchSingleProject({
        id: this.$route.params.id,
        checked: true
      })
    }
    this.proccessingPipelineFilter = true
    this.fetchPipelinesPipelines().then(() => {
      this.setPipeLineFilters()
      this.updateFilter()
      this.proccessingPipelineFilter = false
    })
    // this.updateProjetsTypesCounter()
    // this.updatePipelinesCounter()
    await this.fetchSaveFilters()
    this.initLoading = false
    if (localStorage.getItem('filterCreer')) {
      localStorage.removeItem('filter')
      localStorage.removeItem('filterPrincipal')
      localStorage.removeItem('filterPrincipalId')
      this.selectedFilterId = localStorage.getItem('filterCreer')
      this.notSave = false
      if (this.getSaveFilters && this.getSaveFilters.length) {
        for (let index = 0; index < this.getSaveFilters.length; index++) {
          const saveFilter = this.getSaveFilters[index]
          if (saveFilter.id == localStorage.getItem('filterCreer')) {
            saveFilter.conditions.conditions.map(cond => {
              let formatedFilter = cond.conditions.map(f => {
                return {
                  glue: cond.glue,
                  key: f.field_key,
                  keyLabel: saveFilter.name,
                  operateur: f.operator,
                  value: [f.value]
                }
              })
              this.formatedCustomFilters = [
                formatedFilter.find(e => {
                  return e.keyLabel == saveFilter.name
                })
              ]
            })
          }
        }
      }
      this.applySort(localStorage.getItem('filterCreer'))
    } else if (localStorage.getItem('filter')) {
      localStorage.removeItem('filterCreer')
      localStorage.removeItem('filterPrincipal')
      localStorage.removeItem('filterPrincipalId')
      this.selectedFilterId = null
      this.formatedCustomFilters = JSON.parse(localStorage.getItem('filter'))
      this.updateFilter(true)
      this.currentPage = 1
      this.applySort()
    } else if (localStorage.getItem('filterPrincipal')) {
      localStorage.removeItem('filterCreer')
      localStorage.removeItem('filter')
      this.filterPrincipal = true
      this.selectedFilterId = null
      this.formatedCustomFiltersTH = JSON.parse(
        localStorage.getItem('filterPrincipal')
      )
      this.updateFilter(true)
      this.currentPage = 1
      this.applySort()
    } else {
      await this.fetchProjects({
        per_page: 50,
        page: this.currentPage,
        sort_by_desc: this.currentSort,
        type_id: this.currentType.id
      })
      this.fetchProjects({
        loading: true,
        per_page: 50,
        page: this.currentPage,
        sort_by_desc: this.currentSort,
        type_id: this.currentType.id,
        has_type_travaux: !this.hiddenColonneTypeTravaux == false ? 0 : 1,
        has_filiales_isolations:
          !this.hiddenColonneTypeIsolation == false ? 0 : 1,
        has_cumac_type_renovation: 1
      })
      if (this.selectedFilterId) {
        localStorage.setItem('filterCreer', this.selectedFilterId)
      }
      if (this.formatedCustomFilters) {
        localStorage.setItem(
          'filter',
          JSON.stringify(this.formatedCustomFilters)
        )
      }

      if (this.filterIdSelected) {
        if (this.formatedCustomFiltersTH) {
          localStorage.setItem(
            'filterPrincipal',
            JSON.stringify(this.formatedCustomFiltersTH),
            this.filterIdSelected
          )
        }
        localStorage.setItem(
          'filterPrincipalId',
          JSON.stringify(this.filterIdSelected)
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-content {
  position: relative;
  display: block;
  background-color: #fff;
  height: 99%;
  .header {
    padding: 5px 10px 5px 10px;
    font-size: 12px;
    .project-types-menu {
      display: flex;
      position: relative;
      box-shadow: none;
      margin-top: 0px;
      margin-left: 10px;
      margin-bottom: 4px;
      .project-types-menu-item {
        display: flex;
        align-items: center;
        border: 2px solid #d5d5d5;
        background-color: #ebebeb;
        color: #5e5e5e;
        font-weight: 600;
        padding: 2px 10px;
        cursor: pointer;
        user-select: none;
        .counter {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 5px;
          min-width: 18px;
          width: auto;
          padding: 0 4px;
          height: 18px;
          background-color: #ee220c;
          color: #fff;
          border-radius: 3px;
          font-weight: 500;
        }
        &.active {
          background-color: #d5d5d5;
          .counter {
            background-color: #858585;
          }
        }
      }
    }
    .filter-loader {
      display: block;
      height: 26px;
      width: 672px;
      margin-left: 8px;
      margin-top: 8px;
    }

    .projects-type-loader {
      display: block;
      height: 28px;
      width: 316px;
    }
    .display-flex {
      display: flex;
      align-items: center;
      // margin-top: 8px;
      .filters::-webkit-scrollbar {
        height: 7px;
        width: 7px;
      }
      .filters::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #80808054;
        border-radius: 10px;
      }
      .filters::-webkit-scrollbar-thumb {
        background: #dcdee0;
        border-radius: 10px;
      }
      .filters::-webkit-scrollbar-thumb:hover {
        background: #888;
      }

      .filters {
        display: flex;
        margin-left: 8px;
        font-weight: 600;
        font-size: 11px;
        white-space: nowrap;
        max-height: 121px;
        overflow-x: auto;
        padding: 5px 8px;
        margin-top: 0px;
        &.pag-scroll {
          padding: 5px 8px;
        }
        // padding: 5px;
        .default-filters {
          display: flex;
          .filter-item-container {
            position: relative;
            .filter-item {
              display: flex;
              padding: 2px 14px;
              border-bottom: 2px solid #d5d5d5;
              cursor: pointer;
              user-select: none;
              &.active {
                border-bottom: 2px solid #404346;
              }
              .counter {
                color: #2dabe2;
                margin-left: 5px;
              }
            }
            .filter-childrens {
              display: flex;
              .filter-childrens-item {
                margin-left: 10px;
              }
            }
            .aucun-pip {
              color: #ee220c;
              margin-left: 12px;
            }
          }
          &.custom-on {
            .filter-item-container {
              .filter-item {
                display: none;
                &.active {
                  display: flex;
                  border-bottom: 2px solid #404346;
                }
              }
            }
          }
        }
        .custom-filters {
          display: flex;
          .filter-item-container {
            position: relative;
            .filter-item {
              display: flex;
              padding: 2px 2px 2px 6px;
              margin: 0 2px;
              font-weight: 400;
              user-select: none;
              .action {
                color: #ff6961;
                margin-left: 2px;
                width: 20px;
                text-align: center;
                cursor: pointer;
              }
              .label {
                font-weight: 700;
              }
            }
          }
          &.custom-on {
            .filter-item-container {
              .filter-item {
                display: none;
                // &.active {
                //   display: flex;
                //   border-bottom: 2px solid #404346;
                // }
              }
            }
          }
        }
        .custom-filters-th {
          display: flex;
          // margin-left: 25px;
          // overflow-x: auto;
          // max-width: 90%;
          .filter-item-container-th {
            position: relative;
            display: flex;
            align-items: center;
            .content-filter {
              display: flex;
              align-items: center;
              .filter-item-th {
                display: flex;
                padding: 2px;
                // margin: 0 2px;
                font-weight: 400;
                user-select: none;
                .label {
                  font-weight: 700;
                }
              }
              .action {
                color: #ff6961;
                margin-left: 2px;
                width: 20px;
                text-align: center;
                cursor: pointer;
              }
            }
          }
          &.custom-on {
            .filter-item-container-th {
              .content-filter {
                display: none;
              }
            }
          }
        }
        .icon-custom-filter {
          display: flex;
          font-size: 20px;
          margin-left: 15px;

          .icon-select-filter,
          .icon-save-filter {
            cursor: pointer;
            top: -5px;
            border: 1px solid #d8d8d8;
            padding: 6px;
            background-color: #ebebeb;
            position: relative;
            border-radius: 5px;
            width: 37px;
            &.icon-select-filter {
              margin-left: 20px;
              font-size: 17px;
              // &.top {
              //   top: 2px;
              // }
            }
            &:hover {
              background-color: #c1c1c1;
            }
          }
        }
        .custom-filters-label {
          padding: 2px 14px;
          margin-left: 5px;
          user-select: none;
        }
      }
    }
  }

  .pagination-loader {
    display: block;
    height: 31px;
    width: 300px;
    margin: 2px 20px 6px 20px;
  }

  .pagination-counter {
    display: flex;
    .pagination {
      margin: 2px 10px 5px 20px;
    }
    .counter-filter {
      margin: 8px 0;
      color: #2dabe2;
      font-weight: 600;
      font-size: 12px;
    }
  }
  .pagination-th {
    display: flex;
    align-items: center;
    margin-left: 23px;
    margin-top: -2px;
    margin-bottom: 2px;
    .per-page-th {
      display: flex;
      align-items: center;
      .checkbox-line-th {
        margin-left: 5px;
        .custom-control-input:checked ~ .custom-control-label::before {
          color: #fff;
          border-color: #4285f4;
          background-color: #4285f4;
        }
      }
      .border-th {
        border: 1px solid #ced4da;
        height: 18px;
        margin-right: 6px;
      }
      .label-per-page-th {
        font-size: 14px;
      }
      .select-option-per-page-th {
        margin-left: 12px;
        .per-page-select {
          &:active,
          &:focus {
            outline: unset;
          }
          .custom-select:focus {
            outline: unset;
          }
        }
      }
    }
    .pagination-counter {
      display: flex;
      .pagination {
        margin: 1px 10px 1px 20px;
      }
    }
  }
  .content {
    height: calc(101% - 117px);
    overflow: auto;
    .projects-table {
      padding: 0 20px;
      color: #5e5e5e;
      table {
        width: 1780px;
        border-collapse: collapse;
        table-layout: fixed;
        box-sizing: border-box;
        empty-cells: show;
        outline: 0;

        th,
        td {
          padding: 2px 5px;
          white-space: nowrap;
          color: #5e5e5e;
          text-align: center;
          position: relative;
          font-size: 12px;
          line-height: 1.5;
          overflow: hidden;
          &.uppercase {
            text-transform: uppercase;
          }
          .icon-minus {
            margin-left: -17px;
          }
          .title-icon-minus {
            margin-left: 6px;
          }
          &.sticky-up {
            position: sticky;
            z-index: 2;
            top: 0;
            &.offset {
              top: 25px;
            }
          }
          &.cursor-main {
            cursor: pointer;
          }
          &.sticky {
            position: sticky;
            z-index: 2;
            left: 0;
            &.offset {
              left: 26px;
              top: 0;
            }
            &.offset-2 {
              left: 123px;
              top: 0;
            }
            &.index-3 {
              z-index: 3;
            }
          }
          &.inner-title {
            font-weight: 600;
          }

          &.blue {
            color: #2dabe2;
          }

          &.tagged-cell {
            &.r {
              padding-right: 11px;
            }
            &.l {
              padding-left: 20px;
            }
          }

          .dossier-checkbox {
            margin: 0 auto;
          }

          .extra-border {
            position: absolute;
            top: 0;
            right: 0;
            width: 6px;
            height: 100%;
            background: #d8d8d8;
            z-index: 2;
          }

          .main-td-content {
            // color: #2dabe2;
            color: #8bc34a;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            // &.padding-tootiltip {
            //   padding: 9px 16px;
            // }
          }

          .sub-td-content {
            font-style: italic;
            font-size: 11px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &.blue {
              color: #5d9b9b;
            }
          }

          .pipe-link {
            position: absolute;
            background: #26292c;
            border-radius: 2px;
            color: #fff;
            width: 10px;
            height: 10px;
            bottom: 1px;
            right: 7px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 9px;
          }

          .age-td-content {
            position: absolute;
            top: 0;
            right: 10px;
            color: #ff6961;
            font-weight: 600;
          }
          .color-cell {
            position: absolute;
            border-radius: 33px;
            top: 15px;
            left: 29px;
            width: 20px;
            height: 41%;
            z-index: 1;
            &.red {
              background: #ff6961;
            }
            &.green {
              background: #8bc34a;
            }
            &.orange {
              background: #ff7f00;
            }
            &.grey {
              background-color: #9c9c9c;
            }
          }
          .color-tag {
            position: absolute;
            top: 0;
            left: 0;
            width: 15px;
            height: 100%;
            z-index: 1;

            &.Planifiée,
            &.Planifié,
            &.Posé,
            &.Réalisée,
            &.Signé,
            &.Envoyé,
            &.Accordé,
            &.MPR.Elig,
            &.MPR.Envoyé,
            &.MPR.Accordé,
            &.AL.Elig,
            &.AL.Envoyé,
            &.AL.Accordé,
            &.Validé,
            &.Facturé,
            &.Attente.Pièces,
            &.Ouvert,
            &.Complet {
              background: #b0f2b6;
            }
            &.Annulée,
            &.Annulé,
            &.Refusé,
            &.Non.signé,
            &.MPR.non.Elig,
            &.MPR.Refusé,
            &.AL.non.Elig,
            &.AL.Refusé,
            &.Non.éligible {
              background: #ff6961;
            }
            &.Reportée,
            &.Reporté,
            &.Réglé {
              background: #feca1d;
            }
          }
        }

        tr {
          // height: 25px;
          th {
            background-color: #ebebeb;
            border: 1px solid #d8d8d8;
            font-weight: 700;
          }
          td {
            background-color: #fff;
            border: 1px solid #d8d8d8;
          }
          &.red {
            td {
              .main-td-content {
                color: #ff6961;
                font-style: italic;
                // &.padding-tootiltip {
                //   padding: 9px 16px;
                // }
              }
            }
          }
          &.green {
            td {
              .main-td-content {
                color: #8bc34a;
                // #76c916
                font-style: italic;
              }
            }
          }
          &.orange {
            td {
              .main-td-content {
                color: #ff7f00;
                font-style: italic;
              }
            }
          }
          &.grey {
            td {
              .main-td-content {
                color: #9c9c9c;
                font-style: italic;
              }
            }
          }
          &.yellow {
            td {
              .main-td-content {
                color: #ffe436;
                font-style: italic;
              }
            }
          }
          &.black {
            td {
              .main-td-content {
                color: #656565e3;
                font-style: italic;
              }
            }
          }
          &.purple {
            td {
              .main-td-content {
                color: #6f42c1d1;
                font-style: italic;
              }
            }
          }
          &.blue {
            td {
              .main-td-content {
                color: #2dabe2;
                font-style: italic;
              }
            }
          }
        }

        th {
          cursor: pointer;
          .filter-icon {
            color: #c5c5c5;
            font-size: 13px;
          }
          .icon-sort {
            margin-left: 2px;
          }
          .mrg {
            margin-right: 9px;
          }
        }

        .no-project {
          width: 200px;
          padding: 10px;
        }
      }
    }
    .error {
      padding: 10px 20px;
      color: #ff6961;
    }
  }
}
.projects-table {
  .trigger {
    display: flex !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
  }
}
@media all and (min-width: 150px) and (max-width: 1280px) {
  .page-content {
    position: relative;
    display: block;
    background-color: #fff;
    height: 99%;
    .header {
      padding: 5px 10px 7px 10px;
      font-size: 12px;
      .project-types-menu {
        display: flex;
        position: relative;
        box-shadow: none;
        margin-top: 0px;
        margin-left: 10px;
        .project-types-menu-item {
          display: flex;
          align-items: center;
          border: 2px solid #d5d5d5;
          background-color: #ebebeb;
          color: #5e5e5e;
          font-weight: 600;
          padding: 2px 10px;
          cursor: pointer;
          user-select: none;
          .counter {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 5px;
            min-width: 18px;
            width: auto;
            padding: 0 4px;
            height: 18px;
            background-color: #ee220c;
            color: #fff;
            border-radius: 3px;
            font-weight: 500;
          }
          &.active {
            background-color: #d5d5d5;
            .counter {
              background-color: #858585;
            }
          }
        }
      }
      .filter-loader {
        display: block;
        height: 26px;
        width: 672px;
        margin-left: 8px;
        margin-top: 8px;
      }

      .projects-type-loader {
        display: block;
        height: 28px;
        width: 316px;
      }
      .display-flex {
        display: flex;
        align-items: center;
        .filters::-webkit-scrollbar {
          height: 7px;
          width: 7px;
        }
        .filters::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px #80808054;
          border-radius: 10px;
        }
        .filters::-webkit-scrollbar-thumb {
          background: #dcdee0;
          border-radius: 10px;
        }
        .filters::-webkit-scrollbar-thumb:hover {
          background: #888;
        }

        .filters {
          display: flex;
          margin-left: 8px;
          font-weight: 600;
          font-size: 11px;
          white-space: nowrap;
          max-height: 121px;
          overflow-x: auto;
          padding: 5px 8px;
          &.pag-scroll {
            padding: 10px 8px;
          }
          // padding: 5px;
          .default-filters {
            display: flex;
            .filter-item-container {
              position: relative;
              .filter-item {
                display: flex;
                padding: 2px 14px;
                border-bottom: 2px solid #d5d5d5;
                cursor: pointer;
                user-select: none;
                &.active {
                  border-bottom: 2px solid #404346;
                }
                .counter {
                  color: #2dabe2;
                  margin-left: 5px;
                }
              }
              .filter-childrens {
                display: flex;
                .filter-childrens-item {
                  margin-left: 10px;
                }
              }
              .aucun-pip {
                color: #ee220c;
                margin-left: 12px;
              }
            }
            &.custom-on {
              .filter-item-container {
                .filter-item {
                  display: none;
                  &.active {
                    display: flex;
                    border-bottom: 2px solid #404346;
                  }
                }
              }
            }
          }
          .custom-filters {
            display: flex;
            .filter-item-container {
              position: relative;
              .filter-item {
                display: flex;
                padding: 2px 2px 2px 6px;
                margin: 0 2px;
                font-weight: 400;
                user-select: none;
                .action {
                  color: #ff6961;
                  margin-left: 2px;
                  width: 20px;
                  text-align: center;
                  cursor: pointer;
                }
                .label {
                  font-weight: 700;
                }
              }
            }
            &.custom-on {
              .filter-item-container {
                .filter-item {
                  display: none;
                  // &.active {
                  //   display: flex;
                  //   border-bottom: 2px solid #404346;
                  // }
                }
              }
            }
          }
          .custom-filters-th {
            display: flex;
            // margin-left: 25px;
            // overflow-x: auto;
            // max-width: 90%;
            .filter-item-container-th {
              position: relative;
              display: flex;
              align-items: center;
              .content-filter {
                display: flex;
                align-items: center;
                .filter-item-th {
                  display: flex;
                  padding: 2px;
                  // margin: 0 2px;
                  font-weight: 400;
                  user-select: none;
                  .label {
                    font-weight: 700;
                  }
                }
                .action {
                  color: #ff6961;
                  margin-left: 2px;
                  width: 20px;
                  text-align: center;
                  cursor: pointer;
                }
              }
            }
            &.custom-on {
              .filter-item-container-th {
                .content-filter {
                  display: none;
                }
              }
            }
          }
          .icon-custom-filter {
            display: flex;
            font-size: 20px;
            margin-left: 15px;

            .icon-select-filter,
            .icon-save-filter {
              cursor: pointer;
              top: -5px;
              border: 1px solid #d8d8d8;
              padding: 6px;
              background-color: #ebebeb;
              position: relative;
              border-radius: 5px;
              width: 37px;
              &.icon-select-filter {
                margin-left: 20px;
                font-size: 17px;
                // &.top {
                //   top: 2px;
                // }
              }
              &:hover {
                background-color: #c1c1c1;
              }
            }
          }
          .custom-filters-label {
            padding: 2px 14px;
            margin-left: 5px;
            user-select: none;
          }
        }
      }
    }

    .pagination-loader {
      display: block;
      height: 31px;
      width: 300px;
      margin: 2px 20px 6px 20px;
    }

    .pagination-counter {
      display: flex;
      .pagination {
        margin: 2px 10px 5px 20px;
      }
      .counter-filter {
        margin: 8px 0;
        color: #2dabe2;
        font-weight: 600;
        font-size: 12px;
      }
    }
    .pagination-th {
      display: flex;
      align-items: center;
      margin-left: 23px;
      margin-top: -2px;
      margin-bottom: 2px;
      .per-page-th {
        display: flex;
        align-items: center;
        .checkbox-line-th {
          margin-left: 5px;
          .custom-control-input:checked ~ .custom-control-label::before {
            color: #fff;
            border-color: #4285f4;
            background-color: #4285f4;
          }
        }
        .border-th {
          border: 1px solid #ced4da;
          height: 18px;
          margin-right: 6px;
        }
        .label-per-page-th {
          font-size: 14px;
        }
        .select-option-per-page-th {
          margin-left: 12px;
          .per-page-select {
            &:active,
            &:focus {
              outline: unset;
            }
            .custom-select:focus {
              outline: unset;
            }
          }
        }
      }
      .pagination-counter {
        display: flex;
        .pagination {
          margin: 1px 10px 1px 20px;
        }
      }
    }
    .content {
      height: calc(101% - 127px);
      overflow: auto;
    }
  }
  .projects-table {
    padding: 0 20px;
    color: #5e5e5e;
    table {
      width: 1780px;
      border-collapse: collapse;
      table-layout: fixed;
      box-sizing: border-box;
      empty-cells: show;
      outline: 0;

      th,
      td {
        padding: 2px 5px;
        white-space: nowrap;
        color: #5e5e5e;
        text-align: center;
        position: relative;
        font-size: 12px;
        line-height: 1.5;
        overflow: hidden;
        &.uppercase {
          text-transform: uppercase;
        }
        .icon-minus {
          margin-left: -17px;
        }
        .title-icon-minus {
          margin-left: 6px;
        }
        &.sticky-up {
          position: sticky;
          z-index: 2;
          top: 0;
          &.offset {
            top: 25px;
          }
        }
        &.cursor-main {
          cursor: pointer;
        }
        &.sticky {
          position: sticky;
          z-index: 2;
          left: 0;
          &.offset {
            left: 26px;
            top: 0;
          }
          &.offset-2 {
            left: 123px;
            top: 0;
          }

          &.index-3 {
            z-index: 3;
          }
        }
        &.inner-title {
          font-weight: 600;
        }

        &.blue {
          color: #2dabe2;
        }

        &.tagged-cell {
          &.r {
            padding-right: 11px;
          }
          &.l {
            padding-left: 20px;
          }
        }

        .dossier-checkbox {
          margin: 0 auto;
        }

        .extra-border {
          position: absolute;
          top: 0;
          right: 0;
          width: 6px;
          height: 100%;
          background: #d8d8d8;
          z-index: 2;
        }

        .main-td-content {
          color: #2dabe2;
          font-weight: 600;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          // &.padding-tootiltip {
          //   padding: 9px 16px;
          // }
        }

        .sub-td-content {
          font-style: italic;
          font-size: 11px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &.blue {
            color: #5d9b9b;
          }
        }

        .pipe-link {
          position: absolute;
          background: #26292c;
          border-radius: 2px;
          color: #fff;
          width: 10px;
          height: 10px;
          bottom: 1px;
          right: 7px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 9px;
        }

        .age-td-content {
          position: absolute;
          top: 0;
          right: 10px;
          color: #ff6961;
          font-weight: 600;
        }

        .color-tag {
          position: absolute;
          top: 0;
          left: 0;
          width: 15px;
          height: 100%;
          z-index: 1;

          &.Planifiée,
          &.Planifié,
          &.Posé,
          &.Réalisée,
          &.Signé,
          &.Envoyé,
          &.Accordé,
          &.MPR.Elig,
          &.MPR.Envoyé,
          &.MPR.Accordé,
          &.AL.Elig,
          &.AL.Envoyé,
          &.AL.Accordé,
          &.Validé,
          &.Facturé,
          &.Attente.Pièces,
          &.Ouvert,
          &.Complet {
            background: #b0f2b6;
          }
          &.Annulée,
          &.Annulé,
          &.Refusé,
          &.Non.signé,
          &.MPR.non.Elig,
          &.MPR.Refusé,
          &.AL.non.Elig,
          &.AL.Refusé,
          &.Non.éligible {
            background: #ff6961;
          }
          &.Reportée,
          &.Reporté,
          &.Réglé {
            background: #feca1d;
          }
        }
      }

      tr {
        // height: 25px;
        th {
          background-color: #ebebeb;
          border: 1px solid #d8d8d8;
          font-weight: 700;
        }
        td {
          background-color: #fff;
          border: 1px solid #d8d8d8;
        }
        &.red {
          td {
            .main-td-content {
              color: #ff6961;
              font-style: italic;
              // &.padding-tootiltip {
              //   padding: 9px 16px;
              // }
            }
          }
        }
        &.green {
          td {
            .main-td-content {
              color: #8bc34a;
              // #76c916
              font-style: italic;
            }
          }
        }
        &.orange {
          td {
            .main-td-content {
              color: #ff7f00;
              font-style: italic;
            }
          }
        }
        &.grey {
          td {
            .main-td-content {
              color: #9c9c9c;
              font-style: italic;
            }
          }
        }
        &.yellow {
          td {
            .main-td-content {
              color: #ffe436;
              font-style: italic;
            }
          }
        }
        &.black {
          td {
            .main-td-content {
              color: #656565e3;
              font-style: italic;
            }
          }
        }
        &.purple {
          td {
            .main-td-content {
              color: #6f42c1d1;
              font-style: italic;
            }
          }
        }
        &.blue {
          td {
            .main-td-content {
              color: #2dabe2;
              font-style: italic;
            }
          }
        }
      }

      th {
        cursor: pointer;
        .filter-icon {
          color: #c5c5c5;
          font-size: 13px;
        }
        .icon-sort {
          margin-left: 2px;
        }
        .mrg {
          margin-right: 9px;
        }
      }

      .no-project {
        width: 200px;
        padding: 10px;
      }
    }
  }
  .error {
    padding: 10px 20px;
    color: #ff6961;
  }
}
</style>
<style lang="scss">
div#b-toaster-bottom-right\ projet {
  .b-toaster-slot {
    max-width: 471px;
  }
  button.close {
    padding: 0;
    color: #6c757d;
    opacity: 17;
    background-color: transparent;
    border: 0;
    margin-top: -3px;
    &.warnning {
      color: #e1a700;
    }
    &:hover {
      color: #858585;
      outline: none;
    }
    &:focus {
      outline: none;
    }
  }
  .b-toast {
    max-width: 482px;
  }
  .toast {
    max-width: 470px;
    background-color: rgb(247 247 247);
    border-color: rgb(213 213 213);
    color: #ff6961;
    &.warning {
      color: #e1a700;
    }
    &:focus {
      outline: none;
    }
  }
}
header.toast-header.projet {
  color: #ff6961;
  background-color: #f7f7f7;
  border-bottom-color: #d5d5d5;
  &.warning {
    color: #e1a700;
  }
  .toast-color-button-copy {
    color: #ff6961;
    background-color: #f7f7f7;
    border-color: #f7f7f7;
    &:hover,
    &:focus {
      color: #858585;
      outline: none;
      box-shadow: inherit;
    }
  }
  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #ff6961;
    border-color: #ff6961;
    outline: none;
    box-shadow: inherit;
  }
}
.toast-color-button-copy-warning {
  color: #e1a700;
  background-color: #f7f7f7;
  border-color: #f7f7f7;
  &:hover,
  &:focus {
    color: #e1a700;
    outline: none;
    box-shadow: inherit;
    background-color: #f7f7f7;
    border-color: #f7f7f7;
  }
}

.b-toast-warning .toast .toast-header {
  color: #e1a700;
  background-color: transparent;
  border-bottom-color: rgb(108 117 125 / 19%);
  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #e1a700;
    border-color: #e1a700;
    outline: none;
    box-shadow: inherit;
  }
}
.pagination-th {
  .per-page-th {
    .checkbox-line-th {
      margin-left: 5px;
      .custom-control-input:checked ~ .custom-control-label::before {
        color: #fff;
        border-color: #4285f4;
        background-color: #4285f4;
      }
    }
  }
}
.icon-custom-filter {
  display: flex;
  font-size: 20px;
  margin-left: 15px;

  .icon-select-filter,
  .icon-save-filter {
    cursor: pointer;
    top: -3px;
    border: 1px solid #d8d8d8;
    padding: 6px;
    background-color: #ebebeb;
    position: relative;
    border-radius: 5px;
    width: 37px;
    &.icon-select-filter {
      margin-left: 20px;
      font-size: 17px;
      // &.top {
      //   top: 2px;
      // }
    }
    &:hover {
      background-color: #c1c1c1;
    }
  }
}
.actions-filter-th {
  margin-left: 6px;
  .project-types-menu-button-element {
    .filter-icon {
      margin-left: -1px;
    }
  }
  .btn-light {
    margin-top: -5px;
    font-size: 14px;
    width: 36px;
    color: #212529;
    background-color: #ebebeb;
    border-color: #dfdfdf;
    &:hover {
      color: #212529;
      background-color: #d5d5d5;
      border-color: #d5d5d5;
    }
    &:focus,
    &:active {
      box-shadow: unset;
    }
  }
  .btn-light:not(:disabled):not(.disabled):active,
  .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #ebebeb;
    border-color: #ebebeb;
  }
}
.content-filtre-th {
  display: flex;
  align-items: center;
  padding: 3px;
  margin-left: 10px;
  .select-vue {
    display: flex;
    align-items: center;
    .label-select-vue {
      font-size: 14px;
    }
    .select-option-vue {
      margin-left: 13px;
      .multiselect {
        min-height: 35px;
        .multiselect__select {
          top: -1px;
        }
        .multiselect__tags {
          border-radius: 0px;
          min-height: 34px;
          .multiselect__single {
            padding-left: 5px;
            margin-bottom: 8px;
            font-size: 12px;
          }
        }
        .multiselect__content-wrapper {
          position: absolute;
          display: block;
          background: #fff;
          width: 100%;
          max-height: 240px;
          overflow: auto;
          border: 1px solid #e8e8e8;
          border-top: none;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          z-index: 50;
          font-size: 13px;
          -webkit-overflow-scrolling: touch;
        }
      }
    }
  }
}
.project-types-menu-button {
  margin-left: 10px;
  .project-types-menu-button-element {
    border-radius: 4px;
    .filter-icon {
      margin-left: -1px;
    }
  }
  .dropdown-item.active,
  .dropdown-item:active {
    color: #26292c;
    text-decoration: none;
    background-color: #ebebeb;
  }
  .btn-light {
    color: #ffffff;
    background-color: #28a745;
    border-color: #28a745;
    &:hover {
      color: #ffffff;
      background-color: #0bb231;
      border-color: #0bb231;
    }
  }
  .btn-light:not(:disabled):not(.disabled):active,
  .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #ffffff;
    background-color: #28a745;
    border-color: #28a745;
  }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    font-size: 12px;
    font-weight: 600;
    padding: 4px 10px;
    flex: 1 1 auto;
  }
}
.message-upload-success {
  font-size: 13px;
  font-weight: bold;
  color: #28a745;
}
#modal-save-filters {
  .custom-filters {
    padding: 8px;
    margin-bottom: 20px;
    .filter-item-container {
      position: relative;
      .filter-item {
        display: flex;
        // padding: 2px 2px 2px 6px;
        // margin: 0 2px;
        font-weight: 400;
        user-select: none;
        .action {
          color: #ff6961;
          margin-left: 2px;
          width: 20px;
          text-align: center;
          cursor: pointer;
        }
        .label {
          font-weight: 700;
        }
      }
    }
  }
}
#modal-select-filters {
  .bold {
    font-weight: 600;
  }
  .read-filter {
    padding: 15px;
  }
  .condition-filter {
    .condition-and {
      margin-bottom: 20px;
    }
    .condition-or {
      margin-bottom: 20px;
    }
  }
  .delete-filter {
    padding: 10px;
    margin-bottom: 15px;
    a {
      color: #e73e01;
    }
  }
}
#selected_Filter_th {
  .content-actions-filter-th {
    display: flex;
    align-items: center;
    // justify-content: center;
    // margin-bottom: 25px;
    .select-filter-th {
      .multiselect__tags {
        border-radius: 0px;
      }
    }
    .flex-content {
      display: flex;
      &.disabled-buttons {
        pointer-events: none;
        opacity: 0.4;
      }
      .btn-filter-th {
        .btn-th {
          border-radius: 0px;
          height: 40px;
          &:hover {
            border-radius: 0px;
            // height: 42px;
          }
        }
      }
    }
  }
}
</style>
